<template>
  <div
    v-if="!isMobileView"
    class="w-full h-[82px] px-4 pt-6 pb-4 justify-between items-center inline-flex"
  >
    <div class="flex flex-col gap-4">
      <div
        class="w-auto text-neutral-900 text-lg text-left font-semibold font-['Public Sans'] leading-7"
      >
        Resource Management
      </div>
      <div
        class="p-1 bg-white rounded-lg border border-slate-100 justify-between items-start inline-flex"
      >
        <button
          @click="selectTab('Employees')"
          :class="
            selectedTab === 'Employees'
              ? 'px-3 py-1.5 bg-slate-100 rounded-lg justify-center items-center gap-2 flex'
              : 'px-3 py-1.5 rounded-lg justify-center items-center gap-2 flex'
          "
        >
          <div
            :class="
              selectedTab === 'Employees'
                ? 'text-blue_primary text-sm font-semibold'
                : 'text-gray-500 text-sm font-normal'
            "
          >
            Employees
          </div>
        </button>
        <button
          @click="selectTab('Contractors')"
          :class="
            selectedTab === 'Contractors'
              ? 'px-3 py-1.5 bg-slate-100 rounded-lg justify-center items-center gap-2 flex'
              : 'px-3 py-1.5 rounded-lg justify-center items-center gap-2 flex'
          "
        >
          <div
            :class="
              selectedTab === 'Contractors'
                ? 'text-blue_primary text-sm font-semibold'
                : 'text-gray-500 text-sm font-normal'
            "
          >
            Contractors
          </div>
        </button>
      </div>
    </div>

    <div class="justify-start items-center gap-3 flex">
      <div class="relative">
        <input
          type="text"
          placeholder="Search..."
          v-model="searchQuery"
          @input="fetchFiltered"
          class="px-20 py-[9px] bg-white rounded-lg border border-slate-200 pl-10 pr-2"
        />
        <img
          src="/icons/projects/search.svg"
          class="absolute left-3 top-3 w-5 h-5 pointer-events-none"
          style="pointer-events: none"
        />
      </div>
      <button
        @click="newResourceView"
        class="px-4 py-[9px] bg-blue_primary rounded-lg shadow justify-center items-center gap-2 flex"
      >
        <img
          src="/icons/projects/create-new-project.svg"
          class="w-5 h-5 relative"
        />
        <div class="text-white text-sm font-semibold">Create Employee</div>
      </button>
    </div>
  </div>
  <div v-else class="w-full h-auto px-4 pt-4 pb-2 flex flex-col gap-4 bg-white">
    <div
      class="text-neutral-900 text-base font-semibold font-['Public Sans'] leading-6"
    >
      Resource Management
    </div>
    <!-- Selector de Tabs -->
    <div
      class="p-1 bg-white rounded-lg border border-slate-100 flex justify-between gap-2"
    >
      <button
        @click="selectTab('Employees')"
        :class="[
          'px-3 py-1.5 rounded-lg flex justify-center items-center gap-2',
          selectedTab === 'Employees' ? 'bg-slate-100' : '',
        ]"
      >
        <div
          :class="[
            'text-sm',
            selectedTab === 'Employees'
              ? 'text-blue_primary font-semibold'
              : 'text-gray-500 font-normal',
          ]"
        >
          Employees
        </div>
      </button>
      <button
        @click="selectTab('Contractors')"
        :class="[
          'px-3 py-1.5 rounded-lg flex justify-center items-center gap-2',
          selectedTab === 'Contractors' ? 'bg-slate-100' : '',
        ]"
      >
        <div
          :class="[
            'text-sm',
            selectedTab === 'Contractors'
              ? 'text-blue_primary font-semibold'
              : 'text-gray-500 font-normal',
          ]"
        >
          Contractors
        </div>
      </button>
    </div>
    <div class="relative">
      <input
        type="text"
        placeholder=""
        v-model="searchQuery"
        @input="fetchFiltered"
        class="w-full px-4 py-[10px] bg-white rounded-md border border-slate-200"
      />
      <img
        src="/icons/projects/search.svg"
        class="absolute right-3 top-3.5 w-5 h-5 pointer-events-none"
      />
    </div>
    <button
      @click="newResourceView"
      class="w-full py-3 bg-blue_primary rounded-lg shadow flex justify-center items-center gap-2"
    >
      <img src="/icons/projects/create-new-project.svg" class="w-5 h-5" />
      <div class="text-white text-sm font-semibold">Create Employee</div>
    </button>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import helpers from "../../helpers";

export default {
  emits: ["tab-selected-user-management"],
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const selectedTab = ref(store.getters.selectedTab);
    const searchQuery = ref("");
    const isMobileView = computed(() => store.getters.isMobileView);
    const selectTab = async (tab) => {
      await store.dispatch("updateSelectedTab", tab);
      await store.dispatch("fetchTable", {
        page: 1,
        limit: 10,
        type: "users",
        searchType: tab == "Employees" ? 2 : 3,
      });
      selectedTab.value = tab;
      emit("tab-selected-user-management", tab);
    };

    const newResourceView = () => {
      router.push({ name: "create-new-resource" });
    };

    const fetchFiltered = helpers.createDebouncedFetch(
      store,
      "users",
      selectedTab
    );

    return {
      selectedTab,
      selectTab,
      newResourceView,
      fetchFiltered,
      searchQuery,
      isMobileView,
    };
  },
};
</script>

<style scoped>
</style>
