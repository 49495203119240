<template>
  <div v-if="user_type == 1">
    <LoaderSpinner v-if="flagLoading" />

    <div v-else-if="isMobileView" class="max-w-sm">
      <DashboardOverview style="margin-bottom: 50px" />
      <DashboardWorking style="margin-bottom: 50px" />
      <DashboardCharts style="margin-bottom: 50px" />
      <DashboardTable style="margin-bottom: 50px" />
    </div>
    <div
      v-else
      class="h-screen p-4 grid grid-cols-2 grid-rows-[auto_auto_1fr] gap-4"
    >
      <DashboardOverview
        class="col-span-1 row-span-1 border border-gray-200 rounded-lg p-4"
      />

      <DashboardWorking
        class="col-span-1 row-span-2 border border-gray-200 rounded-lg p-4"
      />

      <DashboardCharts
        class="col-span-1 row-span-1 border border-gray-200 rounded-lg p-4"
      />

      <DashboardTable
        class="col-span-2 row-span-1 border border-gray-200 rounded-lg p-4"
      />
    </div>
  </div>
  <div v-else>
    <LoaderSpinner v-if="flagLoading" />
    <div v-else-if="isMobileView">
      <EDNextDueDateTasks />
    </div>
    <div
      v-else
      class="h-screen gap-4"
    >
      <EDNextDueDateTasks />
    </div>
  </div>
</template>
      
  <script>
import DashboardOverview from "@/components/Dashboard/DashboardOverview.vue";
import DashboardCharts from "@/components/Dashboard/DashboardCharts.vue";
import DashboardTable from "@/components/Dashboard/DashboardTable.vue";
import DashboardWorking from "@/components/Dashboard/DashboardWorking.vue";

import EDNextDueDateTasks from "@/components/Dashboard/EmployeesDashboardTasks.vue";

import LoaderSpinner from "@/components/CircularLoader.vue";
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";

export default {
  components: {
    DashboardOverview,
    DashboardCharts,
    DashboardTable,
    DashboardWorking,
    EDNextDueDateTasks,
    LoaderSpinner,
  },
  setup() {
    const store = useStore();
    const flagLoading = ref(true);
    const isMobileView = computed(() => store.getters.isMobileView);
    const user_type = computed(() =>  store.state.user.type );

    onMounted(async () => {
      try {
        await store.dispatch("fetchTable", {
          page: 1,
          limit: 10,
          type: "projects",
        });
        flagLoading.value = false;
      } catch (error) {
        console.error("Error in getting projects:", error);
      }
    });

    return { flagLoading, isMobileView, user_type };
  },
};
</script>
  
<style scoped>
</style>
  