<template>
  <div
    v-if="isOpen"
    class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
  >
    <LoaderSpinner v-if="flagLoading" />
    <div
      class="w-[572px] bg-white rounded-xl shadow flex flex-col"
      v-else-if="!isMobileView"
    >
      <div
        class="p-3 bg-slate-100 rounded-tl-xl rounded-tr-xl border-b border-slate-200 flex justify-between items-center"
      >
        <div class="text-neutral-900 text-lg font-semibold">
          Expense Details
        </div>
        <button
          @click="closeModal"
          class="p-1 bg-gray-400 rounded-[20px] justify-start items-start gap-2.5 flex"
          style="flex-grow: 0 !important"
        >
          <svg
            class="w-4 h-4 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>
      <div class="p-4 flex flex-col gap-4">
        <div
          class="w-[572px] h-[26px] px-4 justify-between items-center inline-flex"
        >
          <div
            class="flex-col justify-center items-start text-left gap-1 inline-flex"
          >
            <div class="text-slate-500 text-left text-sm font-normal">
              Project
            </div>
            <div
              class="text-neutral-900 text-base font-medium font-['Public Sans'] leading-relaxed"
            >
              {{ data.Project ? data.Project.name : "" }}
            </div>
          </div>
        </div>
        <br />

        <div class="flex justify-between gap-4">
          <div class="flex flex-col">
            <div class="text-slate-500 text-sm text-left font-normal">Date</div>
            <div class="text-neutral-900 text-sm text-left font-semibold">
              {{ helpers.formatDateToDDMMMYYYY(data.date) }}
            </div>
          </div>

          <div class="flex flex-col">
            <div class="text-slate-500 text-left text-sm font-normal">
              Assignee
            </div>
            <div class="flex gap-1 mt-1">
              <img
                v-for="(image, index) in data.Users.slice(0, 3)"
                :key="index"
                :src="image.photo"
                class="w-6 h-6 rounded-full"
                :title="image.name + ' ' + image.last_name"
              />
            </div>
            <div
              v-if="data.Users && data.Users.length > 3"
              class="text-sm text-slate-500"
            >
              +{{ data.Users.length - 3 }}
            </div>
          </div>
        </div>
        <div>
          <div class="text-slate-500 text-left text-sm font-normal">Name</div>
          <div
            class="text-neutral-900 text-left text-base font-normal leading-relaxed"
          >
            {{ data.name }}
          </div>
        </div>
        <div>
          <div class="text-slate-500 text-left text-sm font-normal">
            Comments
          </div>
          <div
            class="text-neutral-900 text-left text-base font-normal leading-relaxed"
          >
            {{ data.comments }}
          </div>
        </div>
        <div>
          <div class="text-slate-500 text-left text-sm font-normal">Type</div>
          <div
            class="text-neutral-900 text-left text-base font-normal leading-relaxed"
          >
            {{ data.type }}
          </div>
        </div>
        <div>
          <div class="text-slate-500 text-left text-sm font-normal">Amount</div>
          <div
            class="text-neutral-900 text-left text-base font-normal leading-relaxed"
          >
            ${{ data.amount }}
          </div>
        </div>

        <div v-if="data.ExpenseFiles" class="max-h-80 overflow-y-auto">
          <div class="text-slate-500 text-left text-sm font-normal">Files</div>
          <FilePreview
            v-for="(file, index) in data.ExpenseFiles"
            :key="index"
            :file-url="file.file_url"
          />
        </div>

        <div>
          <div class="text-slate-500 text-left text-sm font-normal">Status</div>
          <div
            v-if="data.status == 'Submitted'"
            class="px-3 pt-1 mix-blend-multiply bg-blue-50 rounded-2xl justify-start items-center flex"
          >
            <div
              class="text-center text-blue-700 text-left text-sm font-medium font-['Public Sans'] leading-snug"
            >
              {{ data.status }}
            </div>
          </div>
          <div
            v-if="data.status == 'Approved'"
            class="px-3 pt-1 mix-blend-multiply bg-emerald-50 rounded-2xl justify-start items-center flex"
          >
            <div
              class="text-center text-emerald-700 text-left text-sm font-medium font-['Public Sans'] leading-snug"
            >
              {{ data.status }}
            </div>
          </div>
          <div
            v-if="data.status == 'Rejected'"
            class="px-3 pt-1 mix-blend-multiply bg-red-50 rounded-2xl justify-start items-center flex"
          >
            <div
              class="text-center text-red-700 text-left text-sm font-medium font-['Public Sans'] leading-snug"
            >
              {{ data.status }}
            </div>
          </div>
        </div>
        <div
          v-if="status == 'Submitted' && user_type == 1"
          class="px-4 pt-2 pb-6 justify-start items-center gap-3 inline-flex"
        >
          <button
            @click="rejectExpense"
            class="px-3.5 py-[9px] bg-red-400 rounded-lg shadow border border-slate-200 justify-center items-center gap-2 flex"
          >
            <div
              class="text-white text-sm font-semibold font-['Public Sans'] leading-snug"
            >
              Reject Expense
            </div>
          </button>

          <button
            @click="aproveExpense"
            class="px-3.5 py-[9px] bg-blue_primary rounded-lg shadow justify-center items-center gap-2 flex"
          >
            <div
              class="text-white text-sm font-semibold font-['Public Sans'] leading-snug"
            >
              Aprove Expense
            </div>
          </button>
        </div>
      </div>
    </div>
    <div
      class="w-full max-w-sm bg-white rounded-2xl shadow-lg flex flex-col max-h-[80vh] overflow-y-auto"
      v-else
    >
      <!-- Encabezado -->
      <div
        class="p-4 bg-slate-100 rounded-t-2xl border-b border-slate-200 flex justify-between items-center"
      >
        <div class="text-neutral-900 text-lg font-semibold text-left">
          Expense Details
        </div>
        <button
          @click="closeModal"
          class="p-1 bg-gray-400 rounded-full flex items-center justify-center"
        >
          <svg
            class="w-4 h-4 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>

      <!-- Contenido del modal -->
      <div class="p-4 flex flex-col gap-4">
        <div class="flex flex-col text-left">
          <div class="text-slate-500 text-sm">Project</div>
          <div class="text-neutral-900 text-base font-medium">
            {{ data.Project ? data.Project.name : "" }}
          </div>
        </div>

        <!-- Fecha y Asignado -->
        <div class="flex flex-wrap gap-4">
          <div class="flex flex-col w-1/2 text-left">
            <div class="text-slate-500 text-sm">Date</div>
            <div class="text-neutral-900 text-sm font-semibold">
              {{ helpers.formatDateToDDMMMYYYY(data.date) }}
            </div>
          </div>

          <div class="flex flex-col w-1/2 text-left">
            <div class="text-slate-500 text-sm">Assignee</div>
            <div class="flex -space-x-1">
              <div
                v-for="(user, index) in data.Users.slice(0, 5)"
                :key="index"
                class="relative inline-block"
              >
                <img
                  :src="user.photo"
                  class="w-6 h-6 rounded-full border border-white cursor-pointer"
                  :style="{ left: `${index * 14}px`, top: '0' }"
                  @click="toggleTooltip(index, user.id_user)"
                  @mouseleave="hideTooltip(user.id_user)"
                />
                <div
                  v-if="tooltipStates[user.id_user] === index"
                  class="absolute bottom-full mb-1 left-1/2 -translate-x-1/2 whitespace-nowrap bg-black text-white text-xs py-1 px-2 rounded-md"
                >
                  {{ user.name + " " + user.last_name }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Otros detalles -->
        <div class="text-left">
          <div class="text-slate-500 text-sm">Name</div>
          <div class="text-neutral-900 text-base">
            {{ data.name }}
          </div>
        </div>

        <div class="text-left">
          <div class="text-slate-500 text-sm">Comments</div>
          <div class="text-neutral-900 text-base">
            {{ data.comments }}
          </div>
        </div>

        <div class="text-left">
          <div class="text-slate-500 text-sm">Type</div>
          <div class="text-neutral-900 text-base">
            {{ data.type }}
          </div>
        </div>

        <div class="text-left">
          <div class="text-slate-500 text-sm">Amount</div>
          <div class="text-neutral-900 text-base">${{ data.amount }}</div>
        </div>

        <!-- Archivos -->
        <div v-if="data.ExpenseFiles" class="max-h-40 overflow-y-auto">
          <div class="text-slate-500 text-sm">Files</div>
          <FilePreview
            v-for="(file, index) in data.ExpenseFiles"
            :key="index"
            :file-url="file.file_url"
          />
        </div>

        <!-- Estado -->
        <div class="text-left">
          <div class="text-slate-500 text-sm">Status</div>
          <div
            v-if="data.status == 'Submitted'"
            class="px-3 py-1 bg-blue-50 rounded-2xl text-blue-700 text-sm font-medium inline-block"
          >
            {{ data.status }}
          </div>
          <div
            v-else-if="data.status == 'Approved'"
            class="px-3 py-1 bg-emerald-50 rounded-2xl text-emerald-700 text-sm font-medium inline-block"
          >
            {{ data.status }}
          </div>
          <div
            v-else-if="data.status == 'Rejected'"
            class="px-3 py-1 bg-red-50 rounded-2xl text-red-700 text-sm font-medium inline-block"
          >
            {{ data.status }}
          </div>
        </div>

        <!-- Botones de acción -->
        <div
          v-if="status == 'Submitted' && user_type == 1"
          class="flex flex-col gap-2"
        >
          <button
            @click="rejectExpense"
            class="w-full py-2 bg-red-400 text-white rounded-lg shadow"
          >
            Reject Expense
          </button>
          <button
            @click="aproveExpense"
            class="w-full py-2 bg-blue-500 text-white rounded-lg shadow"
          >
            Approve Expense
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import LoaderSpinner from "../../CircularLoader.vue";
import helpers from "../../../helpers/index.js";
import FilePreview from "./FilePreview.vue"; // Import the new component
import { useRoute } from "vue-router";
export default {
  components: {
    LoaderSpinner,
    FilePreview,
  },
  props: {
    isOpen: Boolean,
    id_expense: String,
    status: String,
  },
  emits: ["close"],

  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const id_project = route.params.id;
    const flagLoading = ref(true);
    const data = ref("");
    const user_type = computed(() => store.state.user.type);
    const user_id = computed(() => store.state.user.uid);
    const isMobileView = computed(() => store.getters.isMobileView);
    const selectedType = computed(() => store.getters.selectedType);

    const tooltipStates = ref({});

    const toggleTooltip = (index, itemId) => {
      tooltipStates.value[itemId] =
        tooltipStates.value[itemId] === index ? null : index;
    };

    const hideTooltip = (itemId) => {
      tooltipStates.value[itemId] = null;
    };

    function closeModal() {
      emit("close");
    }

    async function aproveExpense() {
      flagLoading.value = true;
      const data = {
        id_expense: props.id_expense,
        status: "Approved",
        changedBy: user_id.value,
      };
      const resp = await store.dispatch("updateExpenseStatus", data);

      if (resp.ok) {
        await store.dispatch("fetchTable", {
          page: 1,
          limit: 10,
          type: "expenses",
          searchType: selectedType.value,
          id_project: id_project,
        });
        await store.commit("setSnackbar", {
          text: resp.message,
          enabled: true,
        });
        flagLoading.value = false;
        closeModal();
      }
    }

    async function rejectExpense() {
      flagLoading.value = true;
      const data = {
        id_expense: props.id_expense,
        status: "Rejected",
        changedBy: user_id.value,
      };
      const resp = await store.dispatch("updateExpenseStatus", data);

      if (resp.ok) {
        await store.dispatch("fetchTable", {
          page: 1,
          limit: 10,
          type: "expenses",
          searchType: selectedType.value,
          id_project: id_project,
        });
        await store.commit("setSnackbar", {
          text: resp.message,
          enabled: true,
        });
        flagLoading.value = false;
        closeModal();
      }
    }

    onMounted(async () => {
      await store
        .dispatch("fetchExpense", { id_expense: props.id_expense })
        .then((dataFetched) => {
          data.value = dataFetched;
        });

      flagLoading.value = false;
    });

    return {
      closeModal,
      user_type,
      flagLoading,
      data,
      helpers,
      aproveExpense,
      rejectExpense,
      isMobileView,
      tooltipStates,
      toggleTooltip,
      hideTooltip,
    };
  },
};
</script>
  
  <style scoped>
/* Add necessary styles */
</style>
  