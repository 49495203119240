<template>
  <LoaderSpinner v-if="flagLoading" />
  <div v-else-if="!isMobileView">
    <div
      class="w-full h-auto bg-white rounded-bl-[20px] rounded-br-[20px] justify-around items-start flex"
    >
      <div class="w-[15%] flex-col justify-start items-start inline-flex">
        <!-- Project Name Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Project Name
            </div>
          </div>
        </div>
        <!-- Project Name Data -->
        <div
          v-for="(item, index) in table"
          :key="'projectName-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center gap-3 flex"
        >
          <img
            src="/icons/projects/project-name.svg"
            class="w-10 h-10 p-2.5 bg-slate-100 rounded-[20px] justify-center items-center flex"
          />

          <div
            class="grow shrink basis-0 text-slate-600 text-left text-sm font-normal font-['Public Sans'] leading-snug"
          >
            {{ item.name }}
          </div>
        </div>
      </div>

      <div class="w-[15%] flex-col justify-start items-start inline-flex">
        <!-- Client Header -->
        <div
          class="self-stretch h-11 px-2 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Client
            </div>
          </div>
        </div>
        <!-- Client Data -->
        <div
          v-for="(item, index) in table"
          :key="'client-' + index"
          class="self-stretch h-[72px] px-2 py-4 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <img class="w-10 h-10 rounded-[200px]" :src="item.Client.photo" />
          <div class="flex-col justify-start items-start inline-flex">
            <div
              class="text-slate-600 text-left text-sm font-normal font-['Public Sans'] leading-snug"
            >
              {{ item.Client.name }} {{ item.Client.last_name }}
            </div>
          </div>
        </div>
      </div>

      <div class="w-[30%] flex-col justify-start items-start inline-flex">
        <!-- Address Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Address
            </div>
          </div>
        </div>
        <!-- Address Data -->
        <div
          v-for="(item, index) in table"
          :key="'address-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center inline-flex"
        >
          <div
            class="grow shrink basis-0 text-slate-600 text-left text-sm font-normal font-['Public Sans'] leading-snug"
          >
            {{ item.street }} {{ item.apartment_unit }} {{ item.city }},
            {{ item.state }} {{ item.zipcode }}
          </div>
        </div>
      </div>

      <div class="w-[10%] flex-col justify-start items-start inline-flex">
        <!-- Timeline Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Timeline
            </div>
          </div>
        </div>
        <!-- Timeline Data -->
        <div
          v-for="(item, index) in table"
          :key="'timeline-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center inline-flex"
        >
          <div class="flex items-center">
            <span
              class="text-red-600 text-left text-sm font-normal font-['Public Sans'] leading-snug"
            >
              {{
                item.total_time_worked
                  ? parseFloat(Number(item.total_time_worked).toFixed(2)) + "  "
                  : 0 + "  "
              }}</span
            >
            <span
              class="text-slate-600 text-left text-sm font-normal font-['Public Sans'] leading-snug"
              >/ {{ item.time_allocated }} hr</span
            >
          </div>
        </div>
      </div>

      <div class="w-[10%] flex-col justify-start items-start inline-flex">
        <!-- Header Percentage-->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Progress
            </div>
          </div>
        </div>
        <!-- Data -->
        <div
          v-for="(item, index) in projectsWithPercentage"
          :key="'progress-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center inline-flex"
        >
          <div
            class="text-slate-600 text-left text-sm font-normal font-['Public Sans'] leading-snug"
          >
            {{ item.percentage }}%
          </div>
        </div>
      </div>

      <div class="w-[10%] flex-col justify-start items-start inline-flex">
        <!-- Start Date Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-left text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Start Date
            </div>
          </div>
        </div>
        <!-- Start Date Data -->
        <div
          v-for="(item, index) in table"
          :key="'startDate-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center inline-flex"
        >
          <div
            class="text-slate-600 text-left text-sm font-normal font-['Public Sans'] leading-snug"
          >
            {{ helpers.formatDateToMMDDYYYY(item.start_date) }}
          </div>
        </div>
      </div>

      <div class="w-[10%] flex-col justify-start items-start inline-flex">
        <!-- Due Date Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Due Date
            </div>
          </div>
        </div>
        <!-- Due Date Data -->
        <div
          v-for="(item, index) in table"
          :key="'dueDate-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center inline-flex"
        >
          <div
            class="text-slate-600 text-left text-sm font-normal font-['Public Sans'] leading-snug"
          >
            {{ helpers.formatDateToMMDDYYYY(item.due_date) }}
          </div>
        </div>
      </div>

      <div class="w-[10%] o flex-col justify-start items-start inline-flex">
        <!-- Status Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-left text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Status
            </div>
          </div>
        </div>
        <!-- Status Data -->
        <div
          v-for="(item, index) in table"
          :key="'status-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center inline-flex"
        >
          <div
            v-if="item.status == 'Completed'"
            class="px-3 pt-1 mix-blend-multiply bg-emerald-50 rounded-2xl justify-start items-center flex"
          >
            <div
              class="text-center text-emerald-700 text-left text-sm font-medium font-['Public Sans'] leading-snug"
            >
              {{ item.status }}
            </div>
          </div>
          <div
            v-if="item.status == 'In Progress'"
            class="px-3 pt-1 mix-blend-multiply bg-purple-50 rounded-2xl justify-start items-center flex"
          >
            <div
              class="text-center text-purple-700 text-left text-sm font-medium font-['Public Sans'] leading-snug"
            >
              {{ item.status }}
            </div>
          </div>
          <div
            v-if="item.status == 'Active'"
            class="px-3 pt-1 mix-blend-multiply bg-blue-50 rounded-2xl justify-start items-center flex"
          >
            <div
              class="text-center text-blue-700 text-left text-sm font-medium font-['Public Sans'] leading-snug"
            >
              {{ item.status }}
            </div>
          </div>
        </div>
      </div>

      <div class="w-[5%] flex-col justify-start items-start inline-flex">
        <!-- Actions Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Actions
            </div>
          </div>
        </div>
        <!-- Actions Data -->
        <div
          v-for="(item, index) in table"
          :key="'actions-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-center items-center inline-flex"
        >
          <DropdownMenu :item="item" />
        </div>
      </div>
    </div>
    <PPagination />
  </div>
  <div v-else>
    <div
      v-for="(item, index) in table"
      :key="index"
      class="w-full bg-white rounded-xl shadow-md mb-4 p-4 flex flex-col gap-4 text-left"
    >
      <div class="flex items-center gap-3">
        <div>
          <div class="flex items-center gap-2">
            <img
              src="/icons/projects/project-name.svg"
              class="w-8 h-8 p-2 bg-slate-100 rounded-lg"
            />
            <div class="text-slate-600 text-sm">
              <b>{{ item.name }}</b>
            </div>
          </div>
        </div>
        <div class="ml-auto">
          <DropdownMenu :item="item" />
        </div>
      </div>

      <div class="flex items-center gap-3">
        <img class="w-10 h-10 rounded-full" :src="item.Client?.photo" />
        <div class="text-slate-600 text-sm">
          {{ item.Client.name }} {{ item.Client.last_name }}
        </div>
      </div>

      <div class="text-slate-600 text-sm">
        <strong class="text-neutral-900">Address:</strong>
        {{ item.street }} {{ item.apartment_unit }}, {{ item.city }},
        {{ item.state }}
        {{ item.zipcode }}
      </div>

      <!-- Timeline -->
      <div class="text-slate-600 text-sm">
        <strong class="text-neutral-900">Timeline:</strong>
        <span class="text-red-600">
          {{
            item.total_time_worked
              ? " " + parseFloat(Number(item.total_time_worked).toFixed(2))
              : " " + 0
          }}
        </span>
        / {{ item.time_allocated }} hr
      </div>

      <div class="flex justify-between text-slate-600 text-sm">
        <div>
          <strong class="text-neutral-900">Start Date:</strong>
          {{ helpers.formatDateToMMDDYYYY(item.start_date) }}
        </div>
        <div>
          <strong class="text-neutral-900">Due Date:</strong>
          {{ helpers.formatDateToMMDDYYYY(item.due_date) }}
        </div>
      </div>

      <div class="flex items-center gap-2">
        <strong class="text-neutral-900">Status:</strong>
        <div
          v-if="item.status === 'Completed'"
          class="px-3 py-1 bg-emerald-50 text-emerald-700 rounded-2xl"
        >
          {{ item.status }}
        </div>
        <div
          v-else-if="item.status === 'In Progress'"
          class="px-3 py-1 bg-purple-50 text-purple-700 rounded-2xl"
        >
          {{ item.status }}
        </div>
        <div
          v-else-if="item.status === 'Active'"
          class="px-3 py-1 bg-blue-50 text-blue-700 rounded-2xl"
        >
          {{ item.status }}
        </div>
      </div>
    </div>
    <PPagination />
  </div>
</template>
  <script>
import PPagination from "../Projects/ProjectPagination.vue";
import DropdownMenu from "../Projects/ProjectsDropDown.vue";
import LoaderSpinner from "../CircularLoader.vue";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import helpers from "../../helpers";
export default {
  components: {
    PPagination,
    DropdownMenu,
    LoaderSpinner,
  },

  setup() {
    const store = useStore();
    const table = computed(() => store.getters.table);
    const flagLoading = computed(() => store.getters.loading);
    const ppData = ref([]);
    const isMobileView = computed(() => store.getters.isMobileView);

    const projectsWithPercentage = computed(() => {
      return table.value.map((project) => {
        const ppProject = ppData.value.find(
          (pp) => pp.id_project === project.id_project
        );

        const percentage = ppProject
          ? Math.round(
              (ppProject.total_completed_tasks / ppProject.total_tasks) * 100
            )
          : 0;

        return {
          ...project,
          percentage,
        };
      });
    });

    onMounted(async () => {
      const pp = await store.dispatch("fetchProjectPercentage");
      console.log("🚀 ~ onMounted ~ pp:", pp);

      if (pp.data) {
        ppData.value = pp.data;
      }
    });

    return {
      table,
      flagLoading,
      helpers,
      projectsWithPercentage,
      isMobileView,
    };
  },
};
</script>