<template>
  <LoaderSpinner v-if="flagLoading" />
  <div
    v-else
    class="w-full sm:w-[70%] h-auto p-4 bg-white rounded-[20px] shadow flex-col justify-start items-start gap-5 inline-flex"
  >
    <div
      class="self-stretch justify-start items-center gap-[105px] inline-flex"
    >
      <div
        class="text-neutral-900 text-lg font-semibold font-['Public Sans'] leading-7"
      >
        Edit Client Profile
      </div>
    </div>

    <!-- photo -->
    <div class="w-full flex justify-center items-center gap-5">
      <div class="flex flex-col justify-center items-center gap-2">
        <img
          :src="photoPreview || dataClient.photo || 'placeholder-image-url.jpg'"
          alt="Profile Photo"
          class="w-24 h-24 rounded-full object-cover"
        />
        <input
          type="file"
          accept="image/*"
          @change="handlePhotoUpload"
          class="hidden"
          ref="photoInput"
        />
        <button
          @click="triggerPhotoUpload"
          class="px-3 py-2 bg-blue_primary text-white rounded-lg shadow hover:bg-blue_primary"
        >
          Change Photo
        </button>
      </div>
    </div>

    <div class="w-full flex flex-col md:flex-row gap-5">
      <!-- Client Name -->
      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="w-full text-slate-600 text-base text-left font-medium font-['Public Sans'] leading-relaxed"
        >
          Client Name
        </div>
        <input
          v-model="dataClient.name"
          type="text"
          placeholder="Name"
          :class="{
            'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !dataClient.name,
            'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              dataClient.name,
          }"
        />
      </div>
      <!-- Client Last Name -->
      <div class="w-full flex-col text-left justify-start items-start gap-1.5">
        <div
          class="w-full text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Client Last Name
        </div>
        <input
          v-model="dataClient.last_name"
          type="text"
          placeholder="Last name"
          :class="{
            'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !dataClient.last_name,
            'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              dataClient.last_name,
          }"
        />
      </div>
    </div>

    <div
      class="w-full h-[74px] flex-col justify-start items-start gap-1.5 flex"
    >
      <!-- Company Name -->
      <div
        class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex"
      >
        <div
          class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Company Name
        </div>
        <input
          v-model="dataClient.company_name"
          type="text"
          placeholder="Client company"
          :class="{
            'self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid justify-start items-start gap-2 inline-flex':
              !dataClient.company_name,
            'self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid justify-start items-start gap-2 inline-flex':
              dataClient.company_name,
          }"
        />
      </div>
    </div>

    <div class="w-full flex flex-col md:flex-row gap-5">
      <!-- Email -->
      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Email
        </div>
        <input
          v-model="dataClient.email"
          type="email"
          placeholder="mail@example.com"
          :class="{
            'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid justify-start items-start gap-2 inline-flex':
              !isEmailValid(dataClient.email),
            'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid justify-start items-start gap-2 inline-flex':
              isEmailValid(dataClient.email),
          }"
        />
      </div>

      <div class="w-full flex-col justify-start items-start gap-1.5">
        <!-- Phone Number -->
        <div
          class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Phone Number
        </div>
        <input
          v-model="dataClient.phone_number"
          type="tel"
          placeholder="phone number [XXX-XXX-XXXX]"
          @input="updatePhoneNumber"
          :class="{
            'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid justify-start items-start gap-2 inline-flex':
              !isPhoneNumberValid(dataClient.phone_number),
            'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid justify-start items-start gap-2 inline-flex':
              isPhoneNumberValid(dataClient.phone_number),
          }"
        />
      </div>
    </div>

    <div class="w-full flex flex-col md:flex-row gap-5">
      <!-- Street -->
      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Street
        </div>
        <input
          v-model="dataClient.street"
          type="text"
          placeholder="Street address"
          :class="{
            'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !dataClient.street,
            'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              dataClient.street,
          }"
        />
      </div>

      <!-- Apartment/Unit -->
      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Apartment/Unit
        </div>
        <input
          v-model="dataClient.apartment_unit"
          type="text"
          placeholder="Apartment or unit number"
          :class="{
            'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !dataClient.apartment_unit,
            'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              dataClient.apartment_unit,
          }"
        />
      </div>
    </div>

    <div class="w-full flex flex-col md:flex-row gap-5">
      <!-- City -->
      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          City
        </div>
        <input
          v-model="dataClient.city"
          type="text"
          placeholder="City"
          :class="{
            'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !dataClient.city,
            'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              dataClient.city,
          }"
        />
      </div>

      <!-- State -->
      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          State
        </div>
        <input
          v-model="dataClient.state"
          type="text"
          placeholder="State"
          :class="{
            'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !dataClient.state,
            'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              dataClient.state,
          }"
        />
      </div>

      <!-- Zipcode -->
      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Zip Code
        </div>
        <input
          v-model="dataClient.zipcode"
          type="text"
          placeholder="Zip code"
          :class="{
            'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !dataClient.zipcode,
            'w-full px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              dataClient.zipcode,
          }"
        />
      </div>
    </div>
    <div class="w-full justify-end items-center gap-3 inline-flex">
      <button
        @click="handleCancel"
        class="px-3.5 py-[9px] bg-white rounded-lg shadow border border-slate-200 justify-center items-center gap-2 flex"
      >
        <div
          class="text-slate-600 text-sm font-semibold font-['Public Sans'] leading-snug"
        >
          Cancel
        </div>
      </button>
      <button
        @click="handleUpdateClient"
        :disabled="!isFormValid"
        :class="
          !isFormValid
            ? 'px-3.5 py-[9px] bg-blue-300 rounded-lg shadow justify-center items-center gap-2 flex'
            : 'px-3.5 py-[9px] bg-blue_primary rounded-lg shadow justify-center items-center gap-2 flex'
        "
      >
        <div
          :class="
            !isFormValid
              ? 'text-white text-sm font-semibold  leading-snug'
              : 'text-white text-sm font-semibold  leading-snug'
          "
        >
          Update Client
        </div>
      </button>
    </div>
  </div>
</template>
  
  <script>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import LoaderSpinner from "../CircularLoader.vue";

export default {
  name: "EditClientProfile",
  components: {
    LoaderSpinner,
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const clientId = route.params.id;
    const user_id = computed(() => store.state.user.uid);
    const phoneNumberDisplay = ref("");
    const flagLoading = ref(false);
    const dataClient = computed(() => store.getters.detailsData);
    const photoInput = ref(null);
    const photoPreview = ref(null);
    const selectedFile = ref(null);

    const isFormValid = computed(() => {
      return (
        dataClient.value.name &&
        dataClient.value.last_name &&
        dataClient.value.company_name &&
        isEmailValid(dataClient.value.email) &&
        isPhoneNumberValid(dataClient.value.phone_number) &&
        dataClient.value.street &&
        dataClient.value.city &&
        dataClient.value.state &&
        dataClient.value.zipcode
      );
    });

    function isEmailValid(email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    }

    function isPhoneNumberValid(phone) {
      const re = /^\d{10}$/;
      return re.test(phone);
    }
    function updatePhoneNumber(event) {
      const input = event.target.value.replace(/\D/g, "");
      dataClient.value.phone_number = input;
      if (input.length <= 3) {
        phoneNumberDisplay.value = input;
      } else if (input.length <= 6) {
        phoneNumberDisplay.value = `${input.slice(0, 3)}-${input.slice(3)}`;
      } else {
        phoneNumberDisplay.value = `${input.slice(0, 3)}-${input.slice(
          3,
          6
        )}-${input.slice(6)}`;
      }
    }

    const handleCancel = () => {
      router.push({
        name: "client-desc",
        params: { id: dataClient.value.id_client },
      });
    };

    function triggerPhotoUpload() {
      photoInput.value.click();
    }

    async function handlePhotoUpload(event) {
      const file = event.target.files[0];
      if (file) {
        selectedFile.value = file;
        const reader = new FileReader();
        reader.onload = () => {
          photoPreview.value = reader.result;
        };
        reader.readAsDataURL(file);
        dataClient.value.photo = file;
      }
    }

    const handleUpdateClient = async () => {
      flagLoading.value = true;
      const formData = new FormData();
      formData.append("id_client", dataClient.value.id_client);
      formData.append("name", dataClient.value.name);
      formData.append("last_name", dataClient.value.last_name);
      formData.append("company_name", dataClient.value.company_name);
      formData.append("email", dataClient.value.email);
      formData.append("phone_number", dataClient.value.phone_number);
      if (selectedFile.value) {
        formData.append("client_profile_picture", selectedFile.value);
      }
      formData.append("street", dataClient.value.street);
      formData.append("apartment_unit", dataClient.value.apartment_unit);
      formData.append("city", dataClient.value.city);
      formData.append("state", dataClient.value.state);
      formData.append("zipcode", dataClient.value.zipcode);
      formData.append("changedBy", user_id.value);
      const response = await fetch("/new-kambuchi/clients/edit-client", {
        method: "PUT",
        body: formData,
      });
      const editedClient = await response.json();
      if (editedClient.ok) {
        flagLoading.value = false;
        selectedFile.value = null;
        router.push({
          name: "client-desc",
          params: { id: dataClient.value.id_client },
        });
      }
      flagLoading.value = false;
    };

    onMounted(async () => {
      flagLoading.value = true;

      if (Object.keys(dataClient.value).length === 0) {
        await store.dispatch("fetchClient", { id_client: clientId });
      }

      flagLoading.value = false;
    });

    return {
      phoneNumberDisplay,
      flagLoading,
      isFormValid,
      updatePhoneNumber,
      handleCancel,
      handleUpdateClient,
      dataClient,
      isEmailValid,
      isPhoneNumberValid,
      handlePhotoUpload,
      triggerPhotoUpload,
      photoInput,
      photoPreview,
      selectedFile,
    };
  },
};
</script>
  
  <style scoped>
</style>
  