<template>
  <div
    v-if="isOpen"
    class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
  >
    <LoaderSpinner v-if="flagLoading" />
    <div
      class="w-[572px] bg-white rounded-xl shadow flex flex-col max-h-[90vh] overflow-y-auto"
      v-else
    >
      <div
        class="p-3 bg-slate-100 rounded-tl-xl rounded-tr-xl border-b border-slate-200 flex justify-between items-center"
      >
        <div class="text-neutral-900 text-lg font-semibold">
          Create New Expense
        </div>
        <button
          @click="closeModal"
          class="p-1 bg-gray-400 rounded-[20px] justify-start items-start gap-2.5 flex"
          style="flex-grow: 0 !important"
        >
          <svg
            class="w-4 h-4 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>

      <!-- SELECT FOR PROJECT-->
      <div
        v-if="isTab == true"
        class="self-stretch px-4 pb-6 border-b border-slate-200 justify-end items-start gap-5 inline-flex"
      >
        <div class="flex-1 flex-col justify-start items-start gap-1.5 flex">
          <div
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Project:
          </div>
          <select
            v-model="selectedProject"
            @change="handleProjectSelection"
            :class="{
              'self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid justify-start items-start gap-2 inline-flex':
                !selectedProject,
              'self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid justify-start items-start gap-2 inline-flex':
                selectedProject,
            }"
          >
            <option value="" disabled>Select an option</option>
            <option
              v-for="option in selectForProjects"
              :key="option"
              :value="option"
            >
              {{ option.name }}
            </option>
          </select>
        </div>
      </div>

      <div
        class="self-stretch h-[74px] px-4 flex-col justify-start items-start gap-1.5 flex"
      >
        <div
          class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex"
        >
          <div
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Expense Title
          </div>
          <input
            type="text"
            v-model="name"
            placeholder="Title"
            :class="{
              'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                !name,
              'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                name,
            }"
          />
        </div>
      </div>
      <div
        class="self-stretch h-[74px] px-4 flex-col justify-start items-start gap-1.5 flex"
      >
        <div
          class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex"
        >
          <div
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Expense Type
          </div>

          <input
            type="text"
            v-model="type"
            placeholder="Type"
            :class="{
              'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                !type,
              'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                type,
            }"
          />
        </div>
      </div>
      <div
        class="self-stretch h-[74px] px-4 flex-col justify-start items-start gap-1.5 flex"
      >
        <div
          class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex"
        >
          <div
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Expense Amount
          </div>
          <input
            type="number"
            v-model="amount"
            placeholder="150 bucks"
            :class="{
              'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                !amount,
              'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                amount,
            }"
          />
        </div>
      </div>
      <div
        class="self-stretch h-[74px] px-4 flex-col justify-start items-start gap-1.5 flex"
      >
        <div
          class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex"
        >
          <div
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Expense date
          </div>
          <div class="relative w-full">
            <input
              type="date"
              placeholder="dd/mm/yyyy"
              v-model="date"
              :class="{
                'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                  !date,
                'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                  date,
              }"
            />
          </div>
        </div>
      </div>

      <div
        class="self-stretch h-[74px] px-4 flex-col justify-start items-start gap-1.5 flex"
      >
        <div
          class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex"
        >
          <div
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Comments
          </div>
          <textarea
            v-model="comments"
            placeholder="Enter comments"
            class="w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid"
          ></textarea>
        </div>
      </div>
      <br />
      <div>
        <label class="block text-slate-600 text-base font-medium"
          >Expense Files</label
        >
        <div class="p-4 border rounded-lg border-zinc-200">
          <input
            type="file"
            @change="handleFileChange"
            multiple
            class="w-full px-3 py-2 bg-slate-50 rounded-lg border border-slate-200"
          />
          <LoaderSpinner v-if="flagLoadingFiles" />
          <div v-else class="mt-4 space-y-3">
            <div
              v-for="(file, index) in files"
              :key="index"
              class="flex items-center justify-between bg-slate-100 rounded-lg p-2 border border-slate-200"
            >
              <div class="flex items-center space-x-3">
                <img
                  src="/icons/files/file-icon.svg"
                  class="w-10 h-10 rounded-full"
                  alt="File icon"
                />
                <div>
                  <div class="text-neutral-900 text-sm font-medium">
                    {{ file.name }}
                  </div>
                  <div class="text-slate-600 text-xs">
                    {{ formatSize(file.size) }}
                  </div>
                </div>
              </div>
              <button @click="removeFile(index)" class="text-red-700">✖</button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
      >
        Aplies for you and...
      </div>
      <div
        class="p-1 bg-white rounded-lg border border-slate-100 justify-start items-start flex"
      >
        <button
          @click="selectTab('Employees')"
          :class="
            selectedTab === 'Employees'
              ? 'px-3 py-1.5 bg-slate-100 rounded-lg justify-center items-center gap-2 flex'
              : 'px-3 py-1.5 rounded-lg justify-center items-center gap-2 flex'
          "
        >
          <div
            :class="
              selectedTab === 'Employees'
                ? 'text-blue_primary text-sm font-semibold'
                : 'text-gray-500 text-sm font-normal'
            "
          >
            Employees
          </div>
        </button>
        <button
          @click="selectTab('Contractors')"
          :class="
            selectedTab === 'Contractors'
              ? 'px-3 py-1.5 bg-slate-100 rounded-lg justify-center items-center gap-2 flex'
              : 'px-3 py-1.5 rounded-lg justify-center items-center gap-2 flex'
          "
        >
          <div
            :class="
              selectedTab === 'Contractors'
                ? 'text-blue_primary text-sm font-semibold'
                : 'text-gray-500 text-sm font-normal'
            "
          >
            Contractors
          </div>
        </button>
      </div>
      <div
        class="h-auto w-full px-4 flex-col justify-start items-start gap-5 flex"
      >
        <div
          class="h-auto w-full flex-col justify-start items-start gap-3 flex"
        >
          <div
            class="self-stretch h-auto p-4 rounded-lg border border-zinc-200 flex-col justify-start items-center gap-4 flex"
          >
            <input
              v-model="filter"
              type="text"
              placeholder="Search contributor..."
              class="self-stretch px-3 py-2 bg-slate-50 rounded-lg border border-slate-200 justify-start items-center gap-1 inline-flex"
            />
            <LoaderSpinner v-if="flagLoadingUsers" />
            <div
              v-else
              v-for="(user, index) in filteredUsers"
              :key="index"
              class="self-stretch justify-start items-center gap-4 inline-flex"
            >
              <div
                class="grow shrink basis-0 h-10 justify-start items-center gap-3 flex"
              >
                <div class="flex-col justify-start items-end inline-flex">
                  <img :src="user.photo" class="w-10 h-10 rounded-full" />
                </div>
                <div
                  class="grow shrink basis-0 flex-col justify-center items-start gap-0.5 inline-flex"
                >
                  <div
                    class="self-stretch text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
                  >
                    {{ user.name }} {{ user.last_name }}
                  </div>
                  <div
                    class="self-stretch text-slate-600 text-xs font-normal font-['Public Sans'] leading-[18px]"
                  >
                    {{ user.email }}
                  </div>
                </div>
              </div>
              <div
                :class="[
                  'w-5 h-5 relative border-2 rounded cursor-pointer',
                  checkedUsers.includes(user.id_user)
                    ? 'bg-blue_primary'
                    : 'border-blue-700',
                ]"
                @click="toggleCheckbox(user.id_user)"
              >
                <div
                  v-if="checkedUsers.includes(user.id_user)"
                  class="absolute inset-0 flex items-center justify-center text-white"
                >
                  ✓
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="px-4 pt-2 pb-6 justify-end items-center gap-3 inline-flex">
        <button
          @click="closeModal"
          class="px-3.5 py-[9px] bg-white rounded-lg shadow border border-slate-200 justify-center items-center gap-2 flex"
        >
          <div
            class="text-slate-600 text-sm font-semibold font-['Public Sans'] leading-snug"
          >
            Cancel
          </div>
        </button>
        <button
          @click="handleCreateExpense('addExpenseAndCreate')"
          class="px-3.5 text-white py-[9px] rounded-lg shadow justify-center items-center gap-2 flex"
          :class="[
            isFormValid && isProjectSelected
              ? 'bg-blue_primary'
              : 'bg-gray-300',
          ]"
          :disabled="!(isFormValid && isProjectSelected)"
        >
          Create and add new
        </button>
        <button
          @click="handleCreateExpense('addExpense')"
          class="px-3.5 text-white py-[9px] rounded-lg shadow justify-center items-center gap-2 flex"
          :class="[
            isFormValid && isProjectSelected
              ? 'bg-blue_primary'
              : 'bg-gray-600',
          ]"
          :disabled="!(isFormValid && isProjectSelected)"
        >
          Add Expense
        </button>
      </div>
    </div>
  </div>
</template>
    
  <script>
import LoaderSpinner from "../../CircularLoader.vue";
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import helpers from "../../../helpers";

export default {
  props: {
    isOpen: Boolean,
    projectId: Number,
    isTab: Boolean,
  },
  components: { LoaderSpinner },
  emits: ["close"],
  setup(props, { emit }) {
    const store = useStore();

    const flagLoading = ref(false);
    const flagLoadingUsers = ref(false);
    const selectedTab = ref("Employees");
    const checkedUsers = ref([store.state.user.uid]);
    const users = ref([]);
    const filter = ref("");
    const files = ref([]);
    const flagLoadingFiles = ref(false);
    const selectForProjects = ref([]);
    const selectedProject = ref("");
    const name = ref("");
    const type = ref("");
    const amount = ref("");
    const date = ref("");
    const comments = ref("");

    const typeOptions = ref(["Material", "Equipment", "Hotel"]);

    const selectedType = computed(() => store.getters.selectedType);

    const isFormValid = computed(() => {
      return (
        name.value &&
        type.value &&
        amount.value &&
        date.value &&
        files.value.length > 0
      );
    });

    const isProjectSelected = computed(() => {
      return !props.isTab || (props.isTab && selectedProject.value !== "");
    });

    function closeModal() {
      emit("close");
    }

    const selectTab = async (tab) => {
      flagLoadingUsers.value = true;
      selectedTab.value = tab;
      if (props.isTab === true) {
        const resources = await store.dispatch("fetchResourceForAssignTasks", {
          type: selectedTab.value == "Employees" ? 2 : 3,
          id_project: selectedProject.value.id_project,
        });
        users.value = resources.data;
      } else {
        const resources = await store.dispatch("fetchResourceForAssignTasks", {
          type: tab == "Employees" ? 2 : 3,
          id_project: props.projectId,
        });
        users.value = resources.data;
      }

      flagLoadingUsers.value = false;
    };

    function toggleCheckbox(userId) {
      const index = checkedUsers.value.indexOf(userId);
      if (index === -1) {
        checkedUsers.value.push(userId);
      } else {
        checkedUsers.value.splice(index, 1);
      }
    }

    const filteredUsers = computed(() => {
      const userArray = Object.values(users.value);
      return userArray
        .filter((user) =>
          user.name.toLowerCase().includes(filter.value.toLowerCase())
        )
        .slice(0, 5);
    });

    const handleFileChange = (event) => {
      const selectedFiles = Array.from(event.target.files);
      files.value.push(...selectedFiles);
    };

    const removeFile = (index) => {
      files.value.splice(index, 1);
    };

    const formatSize = (size) => {
      if (size < 1024) return size + " bytes";
      else if (size < 1048576) return (size / 1024).toFixed(2) + " KB";
      else return (size / 1048576).toFixed(2) + " MB";
    };

    async function handleCreateExpense(where) {
      if (!isFormValid.value) {
        return;
      }

      try {
        let formData = new FormData();
        flagLoading.value = true;

        if (props.isTab === true) {
          formData.append("name", name.value);
          formData.append("id_project", selectedProject.value.id_project);
          formData.append("date", date.value);
          formData.append("comments", comments.value);
          formData.append("type", helpers.capitalize(type.value));
          formData.append("amount", amount.value);
          formData.append("id_user", store.state.user.uid);

          files.value.forEach((file) => {
            formData.append("expenses_files", file);
          });
        } else {
          formData.append("name", name.value);
          formData.append("id_project", props.projectId);
          formData.append("date", date.value);
          formData.append("comments", comments.value);
          formData.append("type", helpers.capitalize(type.value));
          formData.append("amount", amount.value);
          formData.append("id_user", store.state.user.uid);

          files.value.forEach((file) => {
            formData.append("expenses_files", file);
          });
        }

        const response = await fetch("/new-kambuchi/expenses/add-expenses", {
          method: "POST",
          body: formData,
        });
        const result = await response.json();

        if (result.ok) {
          name.value = "";
          date.value = "";
          comments.value = "";
          type.value = "";
          amount.value = "";
          files.value = [];

          await addResources(result.data.id_expense);
          if (where == "addExpense") {
            await store.dispatch("fetchTable", {
              page: 1,
              limit: 10,
              type: "expenses",
              searchType: selectedType.value,
              id_project: props.projectId,
            });
            closeModal();
          } else if (where == "addExpenseAndCreate") {
            flagLoading.value = false;
          }
        } else {
          flagLoading.value = false;
        }

        checkedUsers.value = [store.state.user.uid];
      } catch (error) {
        flagLoading.value = false;
        console.error("Error creating expense:", error);
      }
    }

    async function addResources(id_expense) {
      flagLoading.value = true;
      const data = {
        resources: checkedUsers.value,
        id_expense: id_expense,
        changedBy: store.state.user.uid
      };
      const resp = await store.dispatch("addExpensesResources", data);
      if (resp.ok) {
        return true;
      }
    }

    const handleProjectSelection = async () => {
      flagLoadingUsers.value = true;
      const resources = await store.dispatch("fetchResourceForAssignTasks", {
        type: selectedTab.value == "Employees" ? 2 : 3,
        id_project: selectedProject.value.id_project,
      });
      users.value = resources.data;
      flagLoadingUsers.value = false;
    };

    onMounted(async () => {
      flagLoading.value = true;
      flagLoadingUsers.value = true;

      if (props.isTab === true) {
        const list = await store.dispatch("fetchActiveProjects");
        selectForProjects.value = list.data;
      } else {
        const resources = await store.dispatch("fetchResourceForAssignTasks", {
          type: selectedTab.value == "Employees" ? 2 : 3,
          id_project: props.projectId,
        });
        users.value = resources.data;
      }
      flagLoading.value = false;
      flagLoadingUsers.value = false;
    });

    return {
      closeModal,
      flagLoading,
      selectTab,
      checkedUsers,
      filteredUsers,
      filter,
      toggleCheckbox,
      files,
      flagLoadingFiles,
      handleFileChange,
      removeFile,
      formatSize,
      typeOptions,
      name,
      type,
      amount,
      date,
      comments,
      handleCreateExpense,
      isFormValid,
      selectedTab,
      flagLoadingUsers,
      selectForProjects,
      selectedProject,
      handleProjectSelection,
      isProjectSelected,
    };
  },
};
</script>
    
    <style scoped>
</style>
    