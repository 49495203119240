<template>
  <div
    v-if="isOpen"
    class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
  >
    <LoaderSpinner v-if="flagLoading" />
    <div
      class="w-11/12 bg-white rounded-xl shadow flex flex-col"
      v-else-if="!isMobileView"
    >
      <div
        class="p-3 bg-slate-100 rounded-tl-xl rounded-tr-xl border-b border-slate-200 flex justify-between items-center"
      >
        <div class="text-neutral-900 text-lg font-semibold">Bill Details</div>
        <button
          @click="closeModal"
          class="p-1 bg-gray-400 rounded-[20px] justify-start items-start gap-2.5 flex"
          style="flex-grow: 0 !important"
        >
          <svg
            class="w-4 h-4 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>
      <div class="p-4 flex flex-col gap-4">
        <div class="flex gap-4">
          <div class="flex flex-col flex-grow">
            <div class="text-slate-500 text-sm text-left font-normal">Date</div>
            <div class="text-neutral-900 text-sm text-left font-semibold">
              {{
                helpers.formatDateNoTimezone(
                  data[0] && data[0].Bill.date_generated
                    ? data[0].Bill.date_generated
                    : ""
                )
              }}
            </div>
          </div>

          <div class="flex flex-col flex-grow">
            <div class="text-slate-500 text-left text-sm font-normal">
              Assignee
            </div>
            <div class="flex gap-1 mt-1">
              <img
                class="w-6 h-6 rounded-full"
                :src="
                  data[0] && data[0].Bill.User.photo
                    ? data[0].Bill.User.photo
                    : ''
                "
                :title="
                  data[0] && data[0].Bill.User.name
                    ? data[0].Bill.User.name + ' ' + data[0].Bill.User.last_name
                    : ''
                "
              />
            </div>
          </div>

          <div class="flex flex-col flex-grow">
            <div class="text-slate-500 text-left text-sm font-normal">Rate</div>
            <div
              class="text-neutral-900 text-left text-base font-normal leading-relaxed"
            >
              $
              {{
                data[0] && data[0].Bill.User.rate ? data[0].Bill.User.rate : ""
              }}
            </div>
          </div>
          <div class="flex flex-col flex-grow">
            <div class="text-slate-500 text-left text-sm font-normal">
              Range Start Date
            </div>
            <div
              class="text-neutral-900 text-left text-base font-normal leading-relaxed"
            >
              {{
                helpers.formatDateToDDMMMYYYY(
                  data[0] && data[0].Bill.range_start_date
                    ? data[0].Bill.range_start_date
                    : ""
                )
              }}
            </div>
          </div>
          <div class="flex flex-col flex-grow">
            <div class="text-slate-500 text-left text-sm font-normal">
              Range End Date
            </div>
            <div
              class="text-neutral-900 text-left text-base font-normal leading-relaxed"
            >
              {{
                helpers.formatDateToDDMMMYYYY(
                  data[0] && data[0].Bill.range_end_date
                    ? data[0].Bill.range_end_date
                    : ""
                )
              }}
            </div>
          </div>
        </div>

        <div>
          <div class="text-slate-500 text-left text-sm font-normal">Status</div>
          <div
            v-if="data[0] && data[0].Bill.status == 'Submitted'"
            class="px-3 pt-1 mix-blend-multiply bg-blue-50 rounded-2xl justify-start items-center flex"
          >
            <div
              class="text-center text-blue-700 text-left text-sm font-medium font-['Public Sans'] leading-snug"
            >
              {{ data[0].Bill.status }}
            </div>
          </div>
          <div
            v-else-if="data[0] && data[0].Bill.status == 'Approved'"
            class="px-3 pt-1 mix-blend-multiply bg-emerald-50 rounded-2xl justify-start items-center flex"
          >
            <div
              class="text-center text-emerald-700 text-left text-sm font-medium font-['Public Sans'] leading-snug"
            >
              {{ data[0].Bill.status }}
            </div>
          </div>
          <div
            v-else-if="data[0] && data[0].Bill.status == 'Rejected'"
            class="px-3 pt-1 mix-blend-multiply bg-red-50 rounded-2xl justify-start items-center flex"
          >
            <div
              class="text-center text-red-700 text-left text-sm font-medium font-['Public Sans'] leading-snug"
            >
              {{ data[0].Bill.status }}
            </div>
          </div>
          <div
            v-else
            class="px-3 pt-1 mix-blend-multiply bg-red-50 rounded-2xl justify-start items-center flex"
          >
            <div
              class="text-center text-red-700 text-left text-sm font-medium font-['Public Sans'] leading-snug"
            >
              ---
            </div>
          </div>
        </div>

        <div class="p-4 flex flex-col gap-4 overflow-y-auto">
          <div
            class="grid gap-4 font-semibold text-slate-700"
            style="grid-template-columns: 15% 15% 25% 10% 10% 10% 15%"
          >
            <div class="text-left">Project Name</div>
            <div class="text-left">Activity</div>
            <div class="text-left">Activity Details</div>
            <div class="text-left">Start Date</div>
            <div class="text-left">Complete Date</div>
            <div class="text-left">Hours Worked</div>
            <div class="text-left">Total $</div>
          </div>
          <div v-if="data.length" class="max-h-80">
            <div
              v-for="(item, index) in data"
              :key="index"
              class="grid gap-4 border-t border-slate-200 pt-2"
              style="grid-template-columns: 15% 15% 25% 10% 10% 10% 15%"
            >
              <div class="text-left">
                {{ item.Task ? item.Task.Project.name : "" }}
              </div>
              <div class="text-left">{{ item.Task ? item.Task.name : "" }}</div>
              <div class="text-left">
                {{ item.Task ? item.Task.description : "" }}
              </div>
              <div class="text-left">
                {{
                  item.TimeTracker
                    ? helpers.formatDateToDDMMMYYYY(item.TimeTracker.started_at)
                    : ""
                }}
              </div>
              <div class="text-left">
                {{
                  item.TimeTracker
                    ? helpers.formatDateToDDMMMYYYY(
                        item.TimeTracker.completed_at
                      )
                    : ""
                }}
              </div>
              <div class="text-left">
                {{
                  item.TimeTracker && item.Bill.User
                    ? helpers
                        .convertSecondsToDecimalHours(
                          item.TimeTracker.seconds_elapsed
                        )
                        .toFixed(2)
                    : ""
                }}
                Hrs
              </div>
              <div class="text-left">
                $
                {{
                  item.TimeTracker && item.Bill.User
                    ? (
                        helpers
                          .convertSecondsToDecimalHours(
                            item.TimeTracker.seconds_elapsed
                          )
                          .toFixed(2) * item.Bill.User.rate
                      ).toFixed(2)
                    : ""
                }}
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="status == 'Submitted' && user_type == 1"
          class="px-4 pt-2 pb-6 justify-end items-center gap-3 inline-flex"
        >
          <button
            @click="rejectBill"
            class="px-3.5 py-[9px] bg-red-400 rounded-lg shadow border border-slate-200 justify-center items-center gap-2 flex"
          >
            <div
              class="text-white text-sm font-semibold font-['Public Sans'] leading-snug"
            >
              Reject Bill
            </div>
          </button>

          <button
            @click="aproveBill"
            class="px-3.5 py-[9px] bg-blue_primary rounded-lg shadow justify-center items-center gap-2 flex"
          >
            <div
              class="text-white text-sm font-semibold font-['Public Sans'] leading-snug"
            >
              Approve Bill
            </div>
          </button>
        </div>
      </div>
    </div>
    <div class="w-11/12 bg-white rounded-lg shadow flex flex-col" v-else>
      <div
        class="p-3 bg-slate-100 rounded-t-lg border-b border-slate-200 flex justify-between items-center"
      >
        <div class="text-neutral-900 text-lg font-semibold">Bill Details</div>
        <button @click="closeModal" class="p-1 bg-gray-400 rounded-full">
          <svg
            class="w-4 h-4 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>

      <div class="p-4 flex flex-col gap-4">
        <div class="grid grid-cols-2 gap-y-4">
          <!-- Date -->
          <div class="flex items-center">
            <div class="w-24 text-slate-500 text-sm text-start">Date</div>
            <div class="text-neutral-900 text-sm font-semibold">
              {{
                helpers.formatDateNoTimezone(
                  data[0] && data[0].Bill.date_generated
                    ? data[0].Bill.date_generated
                    : ""
                )
              }}
            </div>
          </div>
          <br />
          <!-- Assignee -->
          <div class="flex items-center">
            <div class="w-24 text-slate-500 text-sm text-start">Assignee</div>
            <img
              class="w-8 h-8 rounded-full"
              :src="
                data[0] && data[0].Bill.User.photo
                  ? data[0].Bill.User.photo
                  : ''
              "
              :title="
                data[0] && data[0].Bill.User.name ? data[0].Bill.User.name : ''
              "
            />
          </div>
          <br />
          <!-- Rate -->
          <div class="flex items-center">
            <div class="w-24 text-slate-500 text-sm text-start">Rate</div>
            <div class="text-neutral-900 text-sm font-semibold">
              ${{
                data[0] && data[0].Bill.User.rate ? data[0].Bill.User.rate : ""
              }}
            </div>
          </div>
          <br />
          <!-- Status -->
          <div class="flex items-center">
            <div class="w-24 text-slate-500 text-sm text-start">Status</div>
            <div
              v-if="data[0] && data[0].Bill.status === 'Submitted'"
              class="px-3 py-1 bg-blue-50 rounded-2xl text-blue-700 text-sm font-medium inline-block"
            >
              {{ data[0].Bill.status }}
            </div>
            <div
              v-else-if="data[0] && data[0].Bill.status === 'Approved'"
              class="px-3 py-1 bg-emerald-50 rounded-2xl text-emerald-700 text-sm font-medium inline-block"
            >
              {{ data[0].Bill.status }}
            </div>
            <div
              v-else-if="data[0] && data[0].Bill.status === 'Rejected'"
              class="px-3 py-1 bg-red-50 rounded-2xl text-red-700 text-sm font-medium inline-block"
            >
              {{ data[0].Bill.status }}
            </div>
            <div
              v-else
              class="px-3 py-1 bg-red-50 rounded-2xl text-red-700 text-sm font-medium inline-block"
            >
              ---
            </div>
          </div>
        </div>

        <!-- Lista de Tareas -->
        <div class="overflow-y-auto max-h-60">
          <div v-for="(item, index) in data" :key="index" class="border-t py-2">
            <div class="text-sm text-neutral-900 font-semibold text-left">
              {{ item.Task ? item.Task.Project.name : "" }} -
              {{ item.Task ? item.Task.name : "" }}
            </div>
            <div class="text-sm text-slate-500 text-left">
              {{ item.Task ? item.Task.description : "" }}
            </div>
            <div class="text-sm text-left">
              <span class="font-semibold">Hours:</span>
              {{
                item.TimeTracker
                  ? helpers
                      .convertSecondsToDecimalHours(
                        item.TimeTracker.seconds_elapsed
                      )
                      .toFixed(2)
                  : ""
              }}
              Hrs
            </div>
            <div class="text-sm text-left">
              <span class="font-semibold">Total:</span> $
              {{
                item.TimeTracker && item.Bill.User
                  ? (
                      helpers.convertSecondsToDecimalHours(
                        item.TimeTracker.seconds_elapsed
                      ) * item.Bill.User.rate
                    ).toFixed(2)
                  : ""
              }}
            </div>
          </div>
        </div>

        <div
          v-if="status === 'Submitted' && user_type === 1"
          class="flex flex-col gap-3"
        >
          <button
            @click="rejectBill"
            class="w-full px-4 py-2 bg-red-400 text-white rounded-lg text-center font-semibold"
          >
            Reject Bill
          </button>

          <button
            @click="aproveBill"
            class="w-full px-4 py-2 bg-blue-500 text-white rounded-lg text-center font-semibold"
          >
            Approve Bill
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import LoaderSpinner from "../../CircularLoader.vue";
import helpers from "../../../helpers/index.js";

export default {
  components: {
    LoaderSpinner,
  },
  props: {
    isOpen: Boolean,
    id_bill: String,
    status: String,
  },
  emits: ["close"],

  setup(props, { emit }) {
    const store = useStore();

    const flagLoading = ref(true);
    const data = ref("");
    const user_type = computed(() => store.state.user.type);
    const user_id = computed(() => store.state.user.uid);
    const isMobileView = computed(() => store.getters.isMobileView);

    function closeModal() {
      emit("close");
    }

    async function aproveBill() {
      flagLoading.value = true;
      const data = {
        id_bill: props.id_bill,
        status: "Approved",
        changedBy: user_id.value,
      };
      const resp = await store.dispatch("updateBillStatus", data);

      if (resp.ok) {
        await store.commit("setSnackbar", {
          text: resp.message,
          enabled: true,
        });
        await store.dispatch("fetchTable", {
          page: 1,
          limit: 10,
          type: "bills",
          searchType: ["Submitted"],
        });
        flagLoading.value = false;
        closeModal();
      }
    }

    async function rejectBill() {
      flagLoading.value = true;
      const data = {
        id_bill: props.id_bill,
        status: "Rejected",
        changedBy: user_id.value,
      };
      const resp = await store.dispatch("updateBillStatus", data);

      if (resp.ok) {
        await store.commit("setSnackbar", {
          text: resp.message,
          enabled: true,
        });
        await store.dispatch("fetchTable", {
          page: 1,
          limit: 10,
          type: "bills",
          searchType: ["Submitted"],
        });
        flagLoading.value = false;
        closeModal();
      }
    }

    onMounted(async () => {
      await store
        .dispatch("fetchBill", { id_bill: props.id_bill })
        .then((dataFetched) => {
          data.value = dataFetched;
        });

      flagLoading.value = false;
    });

    return {
      closeModal,
      user_type,
      flagLoading,
      data,
      helpers,
      aproveBill,
      rejectBill,
      isMobileView,
    };
  },
};
</script>
  
  <style scoped>
/* Add necessary styles */
</style>
  