<template>
  <div>
    <LoaderSpinner v-if="flagLoading" />
    <div
      v-else-if="!isMobileView"
      class="w-full h-auto bg-white rounded-bl-[20px] rounded-br-[20px] justify-around items-start flex"
    >

      <div class="w-[20%] flex-col justify-start items-start inline-flex">
        <!-- Name Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Name
            </div>
          </div>
        </div>
        <!-- Name Data -->
        <div
          v-for="(item, index) in resourcesTable"
          :key="'taskName-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center gap-8 flex"
        >
          <img
            class="w-10 h-10 rounded-[200px]"
            :src="item.photo"
            alt="Employee Avatar"
          />
          <div
            class="grow shrink basis-0 text-slate-600 text-left text-sm font-normal font-['Public Sans'] leading-snug"
          >
            {{ item.name }} {{ item.last_name }}
          </div>
        </div>
      </div>

      <div class="w-[25%] flex-col justify-start items-start inline-flex">
        <!-- Email Header -->
        <div
          class="self-stretch h-11 px-2 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Email
            </div>
          </div>
        </div>
        <!-- Email Data -->
        <div
          v-for="(item, index) in resourcesTable"
          :key="'assignedTo-' + index"
          class="self-stretch h-[72px] px-2 py-4 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="flex-col justify-start items-start inline-flex">
            <div
              class="text-slate-600 text-left text-sm font-normal font-['Public Sans'] leading-snug"
            >
              {{ item.email }}
            </div>
          </div>
        </div>
      </div>

      <div class="w-[10%] flex-col justify-start items-start inline-flex">
        <!-- Phone Number Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Phone Number
            </div>
          </div>
        </div>
        <!-- Phone Number Data -->
        <div
          v-for="(item, index) in resourcesTable"
          :key="'dueDate-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center inline-flex"
        >
          <div
            class="text-slate-600 text-left text-sm font-normal font-['Public Sans'] leading-snug"
          >
            {{ helpers.formatPhoneNumber(item.phone_number) }}
          </div>
        </div>
      </div>

      <div class="w-[30%] flex-col justify-start items-start inline-flex">
        <!-- Address Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Address
            </div>
          </div>
        </div>
        <!-- Address Data -->
        <div
          v-for="(item, index) in resourcesTable"
          :key="'dueDate-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center inline-flex"
        >
          <div
            class="text-slate-600 text-left text-sm font-normal font-['Public Sans'] leading-snug"
          >
            {{ item.street }} {{ item.apartment_unit }} {{ item.city }},
            {{ item.state }} {{ item.zipcode }}
          </div>
        </div>
      </div>
      
      <div class="w-[10%] flex-col justify-start items-start inline-flex">
        <!-- Salary Period Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Salary Period
            </div>
          </div>
        </div>
        <!-- Salary Period Data -->
        <div
          v-for="(item, index) in resourcesTable"
          :key="'salaryPeriod-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center inline-flex"
        >
          <div
            class="text-slate-600 text-sm text-left font-normal font-['Public Sans'] leading-snug"
          >
            {{ item.salary_period ? `${item.salary_period}` : `-` }}
          </div>
        </div>
      </div>

      <div class="w-[10%] flex-col justify-start items-start inline-flex">
        <!-- Salary Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Salary
            </div>
          </div>
        </div>
        <!-- Salary Data -->
        <div
          v-for="(item, index) in resourcesTable"
          :key="'salary-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center inline-flex"
        >
          <div
            class="text-slate-600 text-sm text-left font-normal font-['Public Sans'] leading-snug"
          >
            {{ item.salary ? `$ ${item.salary}` : `-` }}
          </div>
        </div>
      </div>

      
      <div class="w-[10%] flex-col justify-start items-start inline-flex">
        <!-- Rate Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Rate
            </div>
          </div>
        </div>
        <!-- Rate Data -->
        <div
          v-for="(item, index) in resourcesTable"
          :key="'rate-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center inline-flex"
        >
          <div
            class="text-slate-600 text-sm text-left font-normal font-['Public Sans'] leading-snug"
          >
            {{ item.rate ? `$ ${item.rate}` : `-` }}
          </div>
        </div>
      </div>

      <div class="w-[10%] flex-col justify-start items-start inline-flex">
        <!-- Joining Date Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Joining Date
            </div>
          </div>
        </div>
        <!-- Joining Date Data -->
        <div
          v-for="(item, index) in resourcesTable"
          :key="'dueDate-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-start items-center inline-flex"
        >
          <div
            class="text-slate-600 text-sm text-left font-normal font-['Public Sans'] leading-snug"
          >
            {{ helpers.formatDateToMMDDYYYY(item.created_time) }}
          </div>
        </div>
      </div>

      <div class="w-[5%] flex-col justify-start items-start inline-flex">
        <!-- Actions Header -->
        <div
          class="self-stretch h-11 px-4 py-3 bg-slate-50 border-b border-slate-200 justify-start items-center gap-3 inline-flex"
        >
          <div class="justify-start items-center gap-1 flex">
            <div
              class="text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
            >
              Actions
            </div>
          </div>
        </div>
        <!-- Actions Data -->
        <div
          v-for="(item, index) in resourcesTable"
          :key="'actions-' + index"
          class="self-stretch h-[72px] p-4 border-b border-slate-200 justify-center items-center inline-flex"
        >
          <DropdownMenu :item="item" />
        </div>
      </div>
    </div>
    <div v-else class="w-full bg-white rounded-bl-[20px] rounded-br-[20px] p-4">
      <div
        v-for="(item, index) in resourcesTable"
        :key="'card-' + index"
        class="bg-slate-50 p-4 mb-4 rounded-lg shadow-md flex flex-col gap-4"
      >
        <div class="flex justify-end items-center">
          <DropdownMenu :item="item" />
        </div>
        <div class="flex items-center gap-4">
          <img
            class="w-16 h-16 rounded-full"
            :src="item.photo"
            alt="Employee Avatar"
          />
          <div>
            <h3 class="text-lg font-semibold text-neutral-900">
              {{ item.name }} {{ item.last_name }}
            </h3>
            <p class="text-sm text-slate-600">{{ item.company_name }}</p>
          </div>
        </div>

        <div class="text-sm text-slate-600">
          <p><strong>Email:</strong> {{ item.email }}</p>
          <p>
            <strong>Phone:</strong>
            {{ helpers.formatPhoneNumber(item.phone_number) }}
          </p>
          <p>
            <strong>Address:</strong> {{ item.street }}
            {{ item.apartment_unit }}, {{ item.city }}, {{ item.state }}
            {{ item.zipcode }}
          </p>
         
          <p>
            <strong>Joining Date:</strong>
            {{ helpers.formatDateToMMDDYYYY(item.created_time) }}
          </p>
        </div>
      </div>
    </div>
    <PPagination />
  </div>
</template>
  
  <script>
import PPagination from "../Projects/ProjectPagination.vue";
import DropdownMenu from "./UserManDropDown.vue";
import LoaderSpinner from "../CircularLoader.vue";
import { computed } from "vue";
import { useStore } from "vuex";
import helpers from "../../helpers";

export default {
  components: {
    PPagination,
    DropdownMenu,
    LoaderSpinner,
  },
  setup() {
    const store = useStore();
    const resourcesTable = computed(() => store.getters.table);
    const flagLoading = computed(() => store.getters.loading);
    const isMobileView = computed(() => store.getters.isMobileView);
    return {
      resourcesTable,
      flagLoading,
      helpers,
      isMobileView,
    };
  },
};
</script>
  
  <style scoped>
</style>
  