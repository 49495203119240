<template>
  <LoaderSpinner v-if="flagLoading" />

  <div v-else-if="!isMobileView">
    <div class="w-full px-4 pb-5">
      <div
        class="text-neutral-900 text-lg font-semibold font-['Public Sans'] leading-7"
      >
        Active Users
      </div>
    </div>

    <div class="w-full bg-white rounded-xl border border-slate-200 p-4">
      <div
        v-if="workingUsers.length === 0"
        class="text-slate-600 text-sm font-normal font-['Public Sans']"
      >
        There are no active users currently
      </div>

      <div v-else class="space-y-4">
        <div
          v-for="user in workingUsers"
          :key="user.id"
          class="flex items-start gap-4 p-4 bg-slate-50 rounded-lg"
        >
          <div class="w-3 h-3 bg-green-500 rounded-full mt-2"></div>

          <div class="flex flex-col">
            <div
              class="text-neutral-900 text-base font-medium font-['Public Sans']"
            >
              {{ user.username }}
            </div>

            <div
              class="text-slate-600 text-sm font-normal font-['Public Sans']"
            >
              <b>{{ user.project.name }}</b> · {{ user.task.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="w-full px-4 mt-8" v-else>
    <!-- Título de la sección -->
    <div
      class="text-neutral-900 text-lg font-semibold font-['Public Sans'] leading-7 pb-5"
    >
      Active Users
    </div>

    <!-- Contenedor de la lista de usuarios -->
    <div class="w-full bg-white rounded-xl border border-slate-200 p-4">
      <!-- Mensaje si no hay usuarios activos -->
      <div
        v-if="workingUsers.length === 0"
        class="text-slate-600 text-sm font-normal font-['Public Sans']"
      >
        There are no users currently working.
      </div>

      <!-- Lista de usuarios activos -->
      <div v-else class="space-y-4">
        <div
          v-for="user in workingUsers"
          :key="user.id"
          class="flex items-start gap-4 p-4 bg-slate-50 rounded-lg"
        >
          <!-- Indicativo visual de actividad -->
          <div class="w-3 h-3 bg-green-500 rounded-full mt-2"></div>

          <!-- Información del usuario -->
          <div class="flex flex-col">
            <div
              class="text-neutral-900 text-base font-medium font-['Public Sans']"
            >
              {{ user.username }}
            </div>
            <div
              class="text-slate-600 text-sm font-normal font-['Public Sans']"
            >
              <b>{{ user.project.name }}</b> · {{ user.task.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  
<script>
import LoaderSpinner from "@/components/CircularLoader.vue";
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";

export default {
  components: {
    LoaderSpinner,
  },
  setup() {
    const store = useStore();
    const flagLoading = ref(true);
    const workingUsers = ref([]);

    const isMobileView = computed(() => store.getters.isMobileView);

    onMounted(async () => {
      try {
        const wu = await store.dispatch("fetchUsersWorking");
        workingUsers.value = wu.data;
        flagLoading.value = false;
      } catch (error) {
        console.error("Error in getting projects:", error);
      }
    });

    return { flagLoading, workingUsers, isMobileView };
  },
};
</script>