import api from "../../api";

export default {
  async fetchNextDueDateTasks({ commit }, params) {
    try {
      const response = await api.get(
        "/new-kambuchi/employees-dashboard/next-due-date-tasks",
        params
      );
      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while fetching next due date tasks",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  async fetchHoursByWeek({ commit }, params) {
    try {
      const response = await api.get(
        "/new-kambuchi/employees-dashboard/hours-by-week",
        params
      );
      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while fetching user hours by week",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  
};
