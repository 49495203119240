<template>
  <div
    v-if="isOpen"
    class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 overflow-hidden"
  >
    <LoaderSpinner v-if="flagLoading" />
    <div
      class="w-[572px] max-h-[800px] overflow-y-auto bg-white rounded-xl shadow flex flex-col"
      v-else-if="!isMobileView"
    >
      <!-- Header -->
      <div
        class="p-3 bg-slate-100 rounded-tl-xl rounded-tr-xl border-b border-slate-200 flex justify-between items-center"
      >
        <div class="text-neutral-900 text-lg font-semibold">
          Create New Invoice
        </div>
        <button
          @click="closeModal"
          class="p-1 bg-gray-400 rounded-[20px] justify-start items-start gap-2.5 flex"
          style="flex-grow: 0 !important"
        >
          <svg
            class="w-4 h-4 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>
      <!-- SELECT FOR PROJECT-->
      <div
        v-if="isTab == true"
        class="self-stretch px-4 pb-6 border-b border-slate-200 justify-end items-start gap-5 inline-flex"
      >
        <div class="flex-1 flex-col justify-start items-start gap-1.5 flex">
          <div
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Project:
          </div>
          <select
            v-model="selectedProject"
            :class="{
              'self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid justify-start items-start gap-2 inline-flex':
                !selectedProject,
              'self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid justify-start items-start gap-2 inline-flex':
                selectedProject,
            }"
          >
            <option value="" disabled>Select an option</option>
            <option
              v-for="option in selectForProjects"
              :key="option"
              :value="option"
            >
              {{ option.name }}
            </option>
          </select>
        </div>
      </div>
      <!-- Date Fields -->
      <div class="px-4 pb-4 border-b border-slate-200 flex gap-5">
        <div class="flex-1 flex-col gap-1.5">
          <label class="text-slate-600 text-base font-medium">Start Date</label>
          <input
            v-model="start_date"
            type="date"
            :class="{
              'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                !dateValidation,
              'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                dateValidation,
            }"
          />
        </div>
        <div class="flex-1 flex-col gap-1.5">
          <label class="text-slate-600 text-base font-medium">Deadline</label>
          <input
            v-model="end_date"
            type="date"
            :class="{
              'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                !dateValidation,
              'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                dateValidation,
            }"
          />
        </div>
      </div>

      <!-- Service Selector -->
      <div class="px-4 py-4 border-b border-slate-200">
        <div
          class="relative"
          :class="[
            'border-4 border-solid',
            selectedServices.length > 0 ? 'border-green-400' : 'border-red-400',
          ]"
        >
          <input
            type="text"
            v-model="searchTerm"
            @input="fetchServices"
            placeholder="Search or select a service..."
            class="w-full px-3 py-2 bg-white rounded-lg border border-slate-300 shadow"
          />
          <select
            v-if="filteredServices"
            class="w-full mt-1 px-3 py-2 bg-white rounded-lg border border-slate-300 shadow"
            size="5"
          >
            <option
              v-for="service in filteredServices"
              :key="service.id_service"
              :value="service"
              @click="addService(service)"
            >
              {{ service.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="text-red-600" v-if="duplicatedService">
        Service already added, please modify the quantity instead
      </div>

      <!-- Selected Services -->
      <div class="px-4 py-4 border-b border-slate-200">
        <div class="max-h-[300px] overflow-y-auto">
          <div
            v-for="(service, index) in selectedServices"
            :key="index"
            class="mb-4 p-4 bg-slate-100 rounded-lg shadow"
          >
            <div class="flex justify-between items-center mb-2">
              <div class="font-semibold text-left">{{ service.name }}</div>
              <div class="text-left">
                {{
                  service.description.length > 50
                    ? service.description.substring(0, 50) + "..."
                    : service.description
                }}
              </div>
              <button
                @click="removeService(index)"
                class="p-1 bg-red-500 text-white rounded-full w-8 h-8"
              >
                X
              </button>
            </div>
            <div class="flex gap-3 mb-2">
              Qty:
              <input
                v-model.number="service.quantity"
                type="number"
                placeholder="Quantity"
                class="w-1/3 px-3 py-2 bg-white rounded-lg border border-slate-300 shadow"
              />
              Price:
              <input
                v-model.number="service.price"
                type="number"
                placeholder="Price"
                class="w-1/3 px-3 py-2 bg-white rounded-lg border border-slate-300 shadow"
              />
              Total:
              <input
                :value="'$' + (service.quantity * service.price).toFixed(2)"
                disabled
                class="w-1/3 px-3 py-2 bg-gray-100 rounded-lg border border-slate-300 shadow"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Total -->
      <div class="px-4 py-4 border-b border-slate-200">
        <div class="text-right font-semibold text-lg">
          Total: ${{ total.toFixed(2) }}
        </div>
      </div>

      <!-- Toggles Expenses & Tasks -->
      <div class="px-4 py-4 border-b border-slate-200">
        <div class="flex items-end gap-6">
          <!-- Include Expenses -->
          <label class="flex items-center gap-2">
            <div
              :class="[
                'w-5 h-5 flex items-center justify-center border-2 rounded cursor-pointer',
                includeExpenses
                  ? 'bg-blue_primary border-blue_primary'
                  : 'border-slate-300',
              ]"
              @click="toggleIncludeExpenses"
            >
              <svg
                v-if="includeExpenses"
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 text-white"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 10-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <span class="text-slate-600 text-sm font-medium"
              >Include Expenses</span
            >
          </label>

          <!-- Include Tasks -->
          <label class="flex items-center gap-2">
            <div
              :class="[
                'w-5 h-5 flex items-center justify-center border-2 rounded cursor-pointer',
                includeTasks
                  ? 'bg-blue_primary border-blue_primary'
                  : 'border-slate-300',
              ]"
              @click="toggleIncludeTasks"
            >
              <svg
                v-if="includeTasks"
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 text-white"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 10-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <span class="text-slate-600 text-sm font-medium"
              >Include Tasks</span
            >
          </label>
        </div>
      </div>

      <!-- Notes -->
      <div class="px-4 py-4 border-b border-slate-200">
        <label class="text-slate-600 text-base font-medium">Notes</label>
        <textarea
          v-model="comments"
          placeholder="Enter comments or notes here"
          rows="4"
          class="w-full px-3 py-2 bg-white rounded-lg shadow border border-slate-200"
        ></textarea>
      </div>

      <!-- Footer Buttons -->
      <div class="px-4 py-4 flex justify-end gap-3">
        <button
          @click="closeModal"
          class="px-3.5 py-[9px] bg-white rounded-lg shadow border border-slate-200"
        >
          Cancel
        </button>
        <button
          @click="generateInvoice"
          class="px-3.5 py-[9px] text-white rounded-lg shadow"
          :class="[
            servicesValidation && dateValidation
              ? 'bg-blue_primary'
              : 'bg-gray-600',
          ]"
          :disabled="servicesValidation && dateValidation ? false : true"
        >
          Generate Invoice
        </button>
      </div>
    </div>

    <!-- Mobile view ...................................................... -->
    <div
      class="w-[90%] max-w-md max-h-[90%] overflow-y-auto bg-white rounded-xl shadow flex flex-col"
      v-else
    >
      <!-- Header -->
      <div
        class="p-3 bg-slate-100 rounded-tl-xl rounded-tr-xl border-b border-slate-200 flex justify-between items-center"
      >
        <div class="text-neutral-900 text-base font-semibold">
          Create New Invoice
        </div>
        <button
          @click="closeModal"
          class="p-1 bg-gray-400 rounded-[20px] flex items-center"
        >
          <svg
            class="w-4 h-4 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>
      <!-- SELECT FOR PROJECT-->
      <div
        v-if="isTab == true"
        class="self-stretch px-4 pb-6 border-b border-slate-200 justify-end items-start gap-5 inline-flex"
      >
        <div class="flex-1 flex-col justify-start items-start gap-1.5 flex">
          <div
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Project:
          </div>
          <select
            v-model="selectedProject"
            :class="{
              'self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid justify-start items-start gap-2 inline-flex':
                !selectedProject,
              'self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid justify-start items-start gap-2 inline-flex':
                selectedProject,
            }"
          >
            <option value="" disabled>Select an option</option>
            <option
              v-for="option in selectForProjects"
              :key="option"
              :value="option"
            >
              {{ option.name }}
            </option>
          </select>
        </div>
      </div>

      <!-- Date Fields -->
      <div class="px-4 pb-4 border-b border-slate-200 flex flex-col gap-3">
        <div class="flex flex-col gap-1">
          <label class="text-slate-600 text-sm font-medium">Start Date</label>
          <input
            v-model="start_date"
            type="date"
            :class="{
              'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                !dateValidation,
              'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                dateValidation,
            }"
          />
        </div>
        <div class="flex flex-col gap-1">
          <label class="text-slate-600 text-sm font-medium">Deadline</label>
          <input
            v-model="end_date"
            type="date"
            :class="{
              'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid':
                !dateValidation,
              'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid':
                dateValidation,
            }"
          />
        </div>
      </div>

      <!-- Service Selector -->
      <div class="px-4 py-4 border-b border-slate-200">
        <div
          class="relative"
          :class="[
            'border-4 border-solid',
            selectedServices.length > 0 ? 'border-green-400' : 'border-red-400',
          ]"
        >
          <input
            type="text"
            v-model="searchTerm"
            @input="fetchServices"
            placeholder="Search or select a service..."
            class="w-full px-3 py-2 bg-white rounded-lg border border-slate-300 shadow text-sm"
          />
          <select
            v-if="filteredServices"
            class="w-full mt-1 px-3 py-2 bg-white rounded-lg border border-slate-300 shadow text-sm"
            size="5"
          >
            <option
              v-for="service in filteredServices"
              :key="service.id_service"
              :value="service"
              @click="addService(service)"
            >
              {{ service.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="text-red-600 text-sm" v-if="duplicatedService">
        Service already added, please modify the quantity instead
      </div>

      <!-- Selected Services -->
      <div class="px-4 py-4 border-b border-slate-200">
        <div class="max-h-[200px] overflow-y-auto">
          <div
            v-for="(service, index) in selectedServices"
            :key="index"
            class="mb-3 p-3 bg-slate-100 rounded-lg shadow text-sm"
          >
            <div class="flex justify-between items-center mb-2">
              <div class="font-semibold text-left">{{ service.name }}</div>
              <button
                @click="removeService(index)"
                class="p-1 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center"
              >
                X
              </button>
            </div>
            <div class="flex flex-wrap gap-2">
              <div class="flex items-center gap-1">
                <span>Qty:</span>
                <input
                  v-model.number="service.quantity"
                  type="number"
                  class="w-20 px-2 py-1 bg-white rounded-lg border border-slate-300 shadow text-sm"
                />
              </div>
              <div class="flex items-center gap-1">
                <span>Price:</span>
                <input
                  v-model.number="service.price"
                  type="number"
                  class="w-20 px-2 py-1 bg-white rounded-lg border border-slate-300 shadow text-sm"
                />
              </div>
              <div class="flex items-center gap-1">
                <span>Total:</span>
                <input
                  :value="'$' + (service.quantity * service.price).toFixed(2)"
                  disabled
                  class="w-20 px-2 py-1 bg-gray-100 rounded-lg border border-slate-300 shadow text-sm"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Total -->
      <div
        class="px-4 py-4 border-b border-slate-200 text-right text-sm font-semibold"
      >
        Total: ${{ total.toFixed(2) }}
      </div>

      <!-- Toggles Expenses & Tasks -->
      <div class="px-4 py-4 border-b border-slate-200">
        <div class="flex items-end gap-6">
          <!-- Include Expenses -->
          <label class="flex items-center gap-2">
            <div
              :class="[
                'w-5 h-5 flex items-center justify-center border-2 rounded cursor-pointer',
                includeExpenses
                  ? 'bg-blue_primary border-blue_primary'
                  : 'border-slate-300',
              ]"
              @click="toggleIncludeExpenses"
            >
              <svg
                v-if="includeExpenses"
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 text-white"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 10-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <span class="text-slate-600 text-sm font-medium"
              >Include Expenses</span
            >
          </label>

          <!-- Include Tasks -->
          <label class="flex items-center gap-2">
            <div
              :class="[
                'w-5 h-5 flex items-center justify-center border-2 rounded cursor-pointer',
                includeTasks
                  ? 'bg-blue_primary border-blue_primary'
                  : 'border-slate-300',
              ]"
              @click="toggleIncludeTasks"
            >
              <svg
                v-if="includeTasks"
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 text-white"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 10-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <span class="text-slate-600 text-sm font-medium"
              >Include Tasks</span
            >
          </label>
        </div>
      </div>

      <!-- Notes -->
      <div class="px-4 py-4 border-b border-slate-200">
        <label class="text-slate-600 text-sm font-medium">Notes</label>
        <textarea
          v-model="comments"
          rows="4"
          class="w-full px-3 py-2 bg-white rounded-lg shadow border border-slate-200 text-sm"
          placeholder="Enter comments or notes here"
        ></textarea>
      </div>

      <!-- Footer Buttons -->
      <div class="px-4 py-4 flex justify-end gap-2">
        <button
          @click="closeModal"
          class="px-3 py-2 bg-white rounded-lg shadow border border-slate-200 text-sm"
        >
          Cancel
        </button>
        <button
          @click="generateInvoice"
          class="px-3 py-2 text-white rounded-lg shadow text-sm"
          :class="[
            servicesValidation && dateValidation
              ? 'bg-blue_primary'
              : 'bg-gray-600',
          ]"
          :disabled="!(servicesValidation && dateValidation)"
        >
          Generate Invoice
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import LoaderSpinner from "../../CircularLoader.vue";
import { useStore } from "vuex";

export default {
  props: {
    isOpen: Boolean,
    id_project: String,
    isTab: Boolean,
  },
  emits: ["close"],
  components: { LoaderSpinner },
  setup(props, { emit }) {
    const store = useStore();
    const user_id = computed(() => store.state.user.uid);
    const flagLoading = ref(true);
    const start_date = ref("");
    const end_date = ref("");
    const comments = ref("");
    const searchTerm = ref("");
    const filteredServices = ref({});
    const selectedServices = ref([]);
    const duplicatedService = ref(false);
    const includeTasks = ref(false);
    const includeExpenses = ref(false);
    const isMobileView = computed(() => store.getters.isMobileView);
    const selectForProjects = ref([]);
    const selectedProject = ref("");
    const dateValidation = computed(() => {
      return (
        (!includeTasks.value && !includeExpenses.value) ||
        (start_date.value && end_date.value)
      );
    });
    const servicesValidation = computed(() => {
      if (props.isTab) {
        return selectedServices.value.length > 0 && selectedProject.value;
      } else {
        return selectedServices.value.length > 0;
      }
    });

    const total = computed(() =>
      selectedServices.value.reduce(
        (acc, service) => acc + (service.quantity * service.price || 0),
        0
      )
    );

    function closeModal() {
      emit("close");
    }

    async function generateInvoice() {
      flagLoading.value = true;
      let invoiceData;
      if (props.isTab === true) {
        invoiceData = {
          id_project: selectedProject.value.id_project,
          start_date: start_date.value,
          end_date: end_date.value,
          comments: comments.value,
          services: selectedServices.value,
          includeExpenses: includeExpenses.value,
          includeTasks: includeTasks.value,
          total: total.value,
          changedBy: user_id.value,
        };
      } else {
        invoiceData = {
          id_project: props.id_project,
          start_date: start_date.value,
          end_date: end_date.value,
          comments: comments.value,
          services: selectedServices.value,
          includeExpenses: includeExpenses.value,
          includeTasks: includeTasks.value,
          total: total.value,
          changedBy: user_id.value,
        };
      }

      const resp = await store.dispatch("addInvoice", invoiceData);
      if (resp.ok) {
        await store.dispatch("fetchTable", {
          page: 1,
          limit: 10,
          type: "invoices",
          searchType: "Submitted",
          id_project: props.id_project,
        });
        closeModal();
      }
      flagLoading.value = false;
    }

    async function fetchServices() {
      try {
        const response = await store.dispatch("fetchServicesForInvoice", {
          searchQuery: searchTerm.value,
        });
        filteredServices.value = response;
      } catch (error) {
        console.error("Error fetching services:", error);
      } finally {
        flagLoading.value = false;
      }
    }

    function addService(service) {
      const exists = selectedServices.value.some(
        (s) => s.id_service === service.id_service
      );

      if (!exists) {
        duplicatedService.value = false;
        selectedServices.value.push({
          ...service,
          quantity: 1,
          price: 0,
        });
      } else {
        duplicatedService.value = true;
      }
    }

    function removeService(index) {
      selectedServices.value.splice(index, 1);
    }

    const toggleIncludeExpenses = () => {
      includeExpenses.value = !includeExpenses.value;
    };

    const toggleIncludeTasks = () => {
      includeTasks.value = !includeTasks.value;
    };

    onMounted(async () => {
      await fetchServices();
      if (props.isTab === true) {
        const list = await store.dispatch("fetchActiveProjects");
        selectForProjects.value = list.data;
      }
      flagLoading.value = false;
    });

    return {
      closeModal,
      flagLoading,
      start_date,
      end_date,
      comments,
      generateInvoice,
      searchTerm,
      filteredServices,
      selectedServices,
      fetchServices,
      addService,
      removeService,
      total,
      duplicatedService,
      isMobileView,
      includeTasks,
      includeExpenses,
      toggleIncludeExpenses,
      toggleIncludeTasks,
      dateValidation,
      servicesValidation,
      selectForProjects,
      selectedProject,
    };
  },
};
</script>

<style scoped></style>
