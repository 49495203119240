import user from "./user";
import app from "./app";
import tracker from "./tracker";
import project from "./project";
import pagination from "./pagination";
import clientManagement from "./client-management";
import resourceManagement from "./resource-management";
import task from "./tasks";
import expense from "./expenses";
import invoice from "./invoices";
import timetracker from "./time-tracker";
import timesheet from "./timesheets";
import bill from "./bills";
import accountSettings from "./account-settings";
import pushNotifications from "./push-notifications"
import notifications from "./notifications";
import mainDashboard from "./main-dashboard";
import employeesDashboard from "./employees-dashboard"

export default {
  ...user,
  ...app,
  ...tracker,
  ...project,
  ...clientManagement,
  ...resourceManagement,
  ...pagination,
  ...task,
  ...expense,
  ...invoice,
  ...timetracker,
  ...timesheet,
  ...bill,
  ...accountSettings,
  ...pushNotifications,
  ...notifications,
  ...mainDashboard,
  ...employeesDashboard,
};
