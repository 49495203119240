<template>
  <LoaderSpinner v-if="flagLoading" />
  <div
    v-else
    class="sm:w-[70%] h-auto p-4 bg-white rounded-[20px] shadow flex-col justify-start items-start gap-5 inline-flex"
  >
    <div
      class="self-stretch justify-start items-center gap-[105px] inline-flex"
    >
      <div
        class="text-neutral-900 text-lg font-semibold font-['Public Sans'] leading-7"
      >
        Create New Client
      </div>
    </div>

    <div class="w-full flex flex-col md:flex-row gap-5">
      <!-- Client Name -->
      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="w-full text-slate-600 text-base text-left font-medium font-['Public Sans'] leading-relaxed"
        >
          POC Name
        </div>
        <input
          v-model="clientName"
          type="text"
          placeholder="Name"
          :class="{
            'w-full self-stretch px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !clientName,
            'w-full self-stretch px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              clientName,
          }"
        />
      </div>

      <!-- Client Last Name -->
      <div class="w-full flex-col text-left justify-start items-start gap-1.5">
        <div
          class="w-full text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          POC Last Name
        </div>
        <input
          v-model="clientLastName"
          type="text"
          placeholder="Last name"
          :class="{
            'w-full self-stretch px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !clientLastName,
            'w-full self-stretch px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              clientLastName,
          }"
        />
      </div>
    </div>

    <div
      class="w-full h-[74px] flex-col justify-start items-start gap-1.5 flex"
    >
      <div
        class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex"
      >
        <div
          class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          POC Position
        </div>
        <input
          v-model="position"
          type="text"
          placeholder="Position"
          :class="{
            'self-stretch px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-red-400 border-solid justify-start items-start gap-2 inline-flex':
              !position,
            'self-stretch px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-green-400 border-solid justify-start items-start gap-2 inline-flex':
              position,
          }"
        />
      </div>
    </div>

    <div
      class="w-full h-[74px] flex-col justify-start items-start gap-1.5 flex"
    >
      <div
        class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex"
      >
        <div
          class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Company Name
        </div>
        <input
          v-model="companyName"
          type="text"
          placeholder="Client company"
          :class="{
            'self-stretch px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-red-400 border-solid justify-start items-start gap-2 inline-flex':
              !companyName,
            'self-stretch px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-green-400 border-solid justify-start items-start gap-2 inline-flex':
              companyName,
          }"
        />
      </div>
    </div>

    <div class="w-full flex flex-col md:flex-row gap-5">
      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Email
        </div>
        <input
          v-model="clientEmail"
          type="email"
          placeholder="mail@example.com"
          :class="{
            'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid justify-start items-start gap-2 inline-flex':
              !isEmailValid(clientEmail),
            'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid justify-start items-start gap-2 inline-flex':
              isEmailValid(clientEmail),
          }"
        />
      </div>

      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Phone Number
        </div>
        <input
          v-model="phoneNumberDisplay"
          type="tel"
          placeholder="phone number [XXX-XXX-XXXX]"
          @input="updatePhoneNumber"
          :class="{
            'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid justify-start items-start gap-2 inline-flex':
              !isPhoneNumberValid(phoneNumber),
            'w-full self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid justify-start items-start gap-2 inline-flex':
              isPhoneNumberValid(phoneNumber),
          }"
        />
      </div>
    </div>

    <div class="w-full flex flex-col md:flex-row gap-5">
      <!-- Street -->
      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Street
        </div>
        <input
          v-model="street"
          type="text"
          placeholder="Street address"
          :class="{
            'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !street,
            'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              street,
          }"
        />
      </div>

      <!-- Apartment/Unit -->
      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Apartment/Unit
        </div>
        <input
          v-model="apartmentUnit"
          type="text"
          placeholder="Apartment or unit number"
          class="w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-green-400 border-solid"
        />
      </div>
    </div>

    <div class="w-full flex flex-col md:flex-row gap-5">
      <!-- City -->
      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          City
        </div>
        <input
          v-model="city"
          type="text"
          placeholder="City"
          :class="{
            'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !city,
            'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              city,
          }"
        />
      </div>

      <!-- State -->
      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          State
        </div>
        <input
          v-model="state"
          type="text"
          placeholder="State"
          :class="{
            'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !state,
            'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              state,
          }"
        />
      </div>

      <!-- Zipcode -->
      <div class="w-full flex-col justify-start items-start gap-1.5">
        <div
          class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
        >
          Zip Code
        </div>
        <input
          v-model="zipcode"
          type="text"
          placeholder="Zip code"
          :class="{
            'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-red-400 border-solid':
              !zipcode || zipcode.length > 5,
            'w-full px-3 py-2 capitalize bg-white rounded-lg shadow border-2 border-green-400 border-solid':
              zipcode && zipcode.length <= 5,
          }"
        />
      </div>
    </div>

    <!-- File Upload -->
    <div class="w-full flex-col justify-start items-start gap-1.5">
      <div
        class="text-slate-600 text-left text-base font-medium font-['Public Sans'] leading-relaxed"
      >
        Profile Picture
      </div>
      <input
        type="file"
        @change="handleFileUpload"
        accept="image/*"
        :class="{
          'w-full px-3 py-2  bg-white rounded-lg shadow border-2 border-red-400 border-solid':
            !selectedFile,
          'w-full px-3 py-2  bg-white rounded-lg shadow border-2 border-green-400 border-solid':
            selectedFile,
        }"
      />
    </div>
    <div class="w-full justify-end items-center gap-3 inline-flex">
      <button
        @click="handleCancel()"
        class="px-3.5 py-[9px] bg-white rounded-lg shadow border border-slate-200 justify-center items-center gap-2 flex"
      >
        <div
          class="text-slate-600 text-sm font-semibold font-['Public Sans'] leading-snug"
        >
          Cancel
        </div>
      </button>
      <button
        @click="handleCreateClient"
        :disabled="!isFormValid"
        :class="
          !isFormValid
            ? 'px-3.5 py-[9px] bg-blue-300 rounded-lg shadow justify-center items-center gap-2 flex'
            : 'px-3.5 py-[9px] bg-blue_primary rounded-lg shadow justify-center items-center gap-2 flex'
        "
      >
        <div
          class="text-white text-sm font-semibold font-['Public Sans'] leading-snug"
        >
          Create Client
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import LoaderSpinner from "../CircularLoader.vue";

export default {
  components: {
    LoaderSpinner,
  },
  setup() {
    const store = useStore();
    const user_id = computed(() => store.state.user.uid);
    const router = useRouter();
    const clientName = ref("");
    const clientLastName = ref("");
    const companyName = ref("");
    const clientEmail = ref("");
    const phoneNumber = ref("");
    const phoneNumberDisplay = ref("");
    const street = ref("");
    const apartmentUnit = ref("");
    const city = ref("");
    const state = ref("");
    const zipcode = ref("");
    const position = ref("");
    const flagLoading = ref(false);
    const selectedFile = ref(null);

    function handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        selectedFile.value = file;
      }
    }
    function isEmailValid(email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    }

    function isPhoneNumberValid(phone) {
      const re = /^\d{10}$/;
      return re.test(phone);
    }

    const isFormValid = computed(() => {
      return (
        clientName.value &&
        clientLastName.value &&
        companyName.value &&
        clientEmail.value &&
        isEmailValid(clientEmail.value) &&
        phoneNumber.value &&
        isPhoneNumberValid(phoneNumber.value) &&
        street.value &&
        city.value &&
        state.value &&
        zipcode.value.length > 0 &&
        zipcode.value.length <= 5 &&
        position.value
      );
    });

    async function handleCreateClient() {
      if (!isFormValid.value) {
        return;
      }

      try {
        flagLoading.value = true;

        const formData = new FormData();
        formData.append("name", clientName.value);
        formData.append("last_name", clientLastName.value);
        formData.append("company_name", companyName.value);
        formData.append("email", clientEmail.value);
        formData.append("phone_number", phoneNumber.value);
        if (selectedFile.value) {
          formData.append("client_profile_picture", selectedFile.value);
        }
        formData.append("street", street.value);
        formData.append("apartment_unit", apartmentUnit.value);
        formData.append("city", city.value);
        formData.append("state", state.value);
        formData.append("zipcode", zipcode.value);
        formData.append("position", position.value);
        formData.append("changedBy", user_id.value);

        const response = await fetch("new-kambuchi/clients/add-client", {
          method: "POST",
          body: formData,
        });

        const newClient = await response.json();

        if (newClient.ok) {
          clientName.value = "";
          clientLastName.value = "";
          clientEmail.value = "";
          phoneNumber.value = "";
          street.value = "";
          apartmentUnit.value = "";
          city.value = "";
          state.value = "";
          zipcode.value = "";
          position.value = "";
          selectedFile.value = null;

          router.push({ name: "client-management" });
        } else {
          await store.commit("setSnackbar", {
            text: "Error while adding client.",
            enabled: true,
          });
        }
      } catch (error) {
        console.error("Error creating client:", error);
      } finally {
        flagLoading.value = false;
      }
    }

    function handleCancel() {
      clientName.value = "";
      clientLastName.value = "";
      clientEmail.value = "";
      phoneNumber.value = "";
      street.value = "";
      apartmentUnit.value = "";
      city.value = "";
      state.value = "";
      zipcode.value = "";
      position.value = "";
      router.push({ name: "client-management" });
    }

    function updatePhoneNumber(event) {
      const input = event.target.value.replace(/\D/g, "");
      phoneNumber.value = input;
      if (input.length <= 3) {
        phoneNumberDisplay.value = input;
      } else if (input.length <= 6) {
        phoneNumberDisplay.value = `${input.slice(0, 3)}-${input.slice(3)}`;
      } else {
        phoneNumberDisplay.value = `${input.slice(0, 3)}-${input.slice(
          3,
          6
        )}-${input.slice(6)}`;
      }
    }

    return {
      clientName,
      clientLastName,
      companyName,
      clientEmail,
      phoneNumber,
      phoneNumberDisplay,
      street,
      apartmentUnit,
      city,
      state,
      zipcode,
      position,
      isEmailValid,
      isPhoneNumberValid,
      isFormValid,
      handleCreateClient,
      updatePhoneNumber,
      flagLoading,
      handleCancel,
      handleFileUpload,
      selectedFile,
    };
  },
};
</script>
