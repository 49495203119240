<template>
  <div
    v-if="isOpen"
    class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
  >
    <LoaderSpinner v-if="flagLoading" />
    <div
      class="w-[572px] bg-white rounded-xl shadow flex flex-col"
      v-else-if="!isMobileView"
    >
      <div
        class="p-3 bg-slate-100 rounded-tl-xl rounded-tr-xl border-b border-slate-200 flex justify-between items-center"
      >
        <div class="text-neutral-900 text-lg font-semibold">
          Create New Timesheet
        </div>
        <button
          @click="closeModal"
          class="p-1 bg-gray-400 rounded-[20px] justify-start items-start gap-2.5 flex"
          style="flex-grow: 0 !important"
        >
          <svg
            class="w-4 h-4 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>
      <br />

      <div
        v-if="isTab == true && userType == 1"
        class="self-stretch px-4 pb-6 border-b border-slate-200 justify-end items-start gap-5 inline-flex"
      >
        <div class="flex-1 flex-col justify-start items-start gap-1.5 flex">
          <div
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Employee:
          </div>
          <select
            v-model="selectedEmployee"
            :class="{
              'self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid justify-start items-start gap-2 inline-flex':
                !selectedEmployee,
              'self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid justify-start items-start gap-2 inline-flex':
                selectedEmployee,
            }"
          >
            <option value="" disabled>Select an option</option>
            <option
              v-for="option in selectForEmployees"
              :key="option"
              :value="option"
            >
              {{ option.name + " " + option.last_name }}
            </option>
          </select>
        </div>
      </div>

      <div
        class="self-stretch px-4 pb-6 border-b border-slate-200 justify-end items-start gap-5 inline-flex"
      >
        <div
          class="grow shrink basis-0 self-stretch flex-col justify-start items-start gap-1.5 inline-flex"
        >
          <div
            class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex"
          >
            <div
              class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
            >
              Start Date
            </div>
            <div class="relative w-full">
              <input
                v-model="start_date"
                type="date"
                placeholder="dd/mm/yyyy"
                class="self-stretch px-3 py-2 bg-white rounded-lg shadow w-full"
                :class="{
                  'border-2 border-red-400 border-solid': !start_date,
                  'border-2 border-green-400 border-solid': start_date,
                }"
              />
            </div>
          </div>
        </div>
        <div
          class="grow shrink basis-0 self-stretch flex-col justify-start items-start gap-1.5 inline-flex"
        >
          <div
            class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex"
          >
            <div
              class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
            >
              Deadline
            </div>
            <div class="relative w-full">
              <input
                v-model="end_date"
                type="date"
                placeholder="dd/mm/yyyy"
                class="self-stretch px-3 py-2 bg-white rounded-lg shadow w-full"
                :class="{
                  'border-2 border-red-400 border-solid': !end_date,
                  'border-2 border-green-400 border-solid': end_date,
                }"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="px-4 pt-2 pb-6 justify-end items-end gap-3 inline-flex">
        <button
          @click="closeModal"
          class="px-3.5 py-[9px] bg-white rounded-lg shadow border border-slate-200 justify-center items-center gap-2 flex"
        >
          <div
            class="text-slate-600 text-sm font-semibold font-['Public Sans'] leading-snug"
          >
            Cancel
          </div>
        </button>

        <button
          @click="generateTimesheet"
          class="px-3.5 py-[9px] bg-blue_primary rounded-lg shadow justify-center items-center gap-2 flex"
          :class="[validation ? 'bg-blue_primary' : 'bg-gray-600']"
          :disabled="validation ? false : true"
        >
          <div
            class="text-white text-sm font-semibold font-['Public Sans'] leading-snug"
          >
            Generate Timesheet
          </div>
        </button>
      </div>
    </div>
    <div
      class="w-full max-w-sm bg-white rounded-xl shadow flex flex-col mx-auto"
      v-else
    >
      <div
        class="p-3 bg-slate-100 rounded-t-xl border-b border-slate-200 flex justify-between items-center"
      >
        <div class="text-neutral-900 text-base font-semibold">
          Create New Timesheet
        </div>
        <button
          @click="closeModal"
          class="p-1 bg-gray-400 rounded-full flex items-center justify-center"
        >
          <svg
            class="w-4 h-4 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>

      <div
        v-if="isTab == true && userType == 1"
        class="self-stretch px-4 pb-6 border-b border-slate-200 justify-end items-start gap-5 inline-flex"
      >
        <div class="flex-1 flex-col justify-start items-start gap-1.5 flex">
          <div
            class="text-slate-600 text-base font-medium font-['Public Sans'] leading-relaxed"
          >
            Employee:
          </div>
          <select
            v-model="selectedEmployee"
            :class="{
              'self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-red-400 border-solid justify-start items-start gap-2 inline-flex':
                !selectedEmployee,
              'self-stretch px-3 py-2 bg-white rounded-lg shadow border-2 border-green-400 border-solid justify-start items-start gap-2 inline-flex':
                selectedEmployee,
            }"
          >
            <option value="" disabled>Select an option</option>
            <option
              v-for="option in selectForEmployees"
              :key="option"
              :value="option"
            >
              {{ option.name + " " + option.last_name }}
            </option>
          </select>
        </div>
      </div>

      <div class="px-4 py-4 border-b border-slate-200 flex flex-col gap-4">
        <div class="flex flex-col gap-1.5">
          <div class="text-slate-600 text-sm font-medium font-['Public Sans']">
            Start Date
          </div>
          <input
            v-model="start_date"
            type="date"
            placeholder="dd/mm/yyyy"
            class="w-full px-3 py-2 bg-white rounded-lg shadow"
            :class="{
              'border-2 border-red-400 border-solid': !start_date,
              'border-2 border-green-400 border-solid': start_date,
            }"
          />
        </div>

        <div class="flex flex-col gap-1.5">
          <div class="text-slate-600 text-sm font-medium font-['Public Sans']">
            Deadline
          </div>
          <input
            v-model="end_date"
            type="date"
            placeholder="dd/mm/yyyy"
            class="w-full px-3 py-2 bg-white rounded-lg shadow"
            :class="{
              'border-2 border-red-400 border-solid': !end_date,
              'border-2 border-green-400 border-solid': end_date,
            }"
          />
        </div>
      </div>

      <div class="px-4 py-4 flex flex-col sm:flex-row gap-3">
        <button
          @click="closeModal"
          class="w-full sm:w-auto px-3 py-2 bg-white rounded-lg shadow border border-slate-200 text-slate-600 text-sm font-semibold"
        >
          Cancel
        </button>

        <button
          @click="generateTimesheet"
          class="w-full sm:w-auto px-3 py-2 bg-blue_primary rounded-lg shadow text-white text-sm font-semibold"
          :class="[validation ? 'bg-blue_primary' : 'bg-gray-600']"
          :disabled="validation ? false : true"
        >
          Generate Timesheet
        </button>
      </div>
    </div>
  </div>
</template>
  
<script>
import { onMounted, ref, computed } from "vue";
import LoaderSpinner from "../../CircularLoader.vue";
import { useStore } from "vuex";

export default {
  props: {
    isOpen: Boolean,
    isTab: Boolean,
  },
  emits: ["close"],
  components: { LoaderSpinner },
  setup(props, { emit }) {
    const store = useStore();
    const flagLoading = ref(true);
    const start_date = ref("");
    const end_date = ref("");
    const isMobileView = computed(() => store.getters.isMobileView);
    const selectedType = computed(() => store.getters.selectedType);
    const selectForEmployees = ref([]);
    const selectedEmployee = ref("");
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const validation = computed(() => {
      const hasStartAndEndDate = start_date.value && end_date.value;
      const isTabValid =
        userType.value == 2 ||
        !props.isTab ||
        (props.isTab && selectedEmployee.value);

      return hasStartAndEndDate && isTabValid;
    });
    const userType = computed(() => store.state.user.type);

    function closeModal() {
      start_date.value = "";
      end_date.value = "";
      selectedEmployee.value = "";
      emit("close");
    }

    async function generateTimesheet() {
      flagLoading.value = true;
      let data;
      if (props.isTab === true) {
        if (userType.value == 2) {
          data = {
            start_date: start_date.value,
            end_date: end_date.value,
            id_user: store.state.user.uid,
            changedBy: store.state.user.uid,
          };
        } else {
          data = {
            start_date: start_date.value,
            end_date: end_date.value,
            id_user: selectedEmployee.value.id_user,
            changedBy: store.state.user.uid,
          };
        }
      } else {
        data = {
          start_date: start_date.value,
          end_date: end_date.value,
          id_user: store.state.user.uid,
          changedBy: store.state.user.uid,
        };
      }
      data.userTimezone = userTimezone;

      const resp = await store.dispatch("addTimesheet", data);
      start_date.value = "";
      end_date.value = "";
      flagLoading.value = false;
      if (resp.ok) {
        await store.dispatch("fetchTable", {
          page: 1,
          limit: 10,
          type: "timesheets",
          searchType: selectedType.value,
        });
        closeModal();
      } else {
        closeModal();
      }
    }

    onMounted(async () => {
      if (props.isTab === true && userType.value == 1) {
        const employeesList = await store.dispatch("fetchEmployees");
        selectForEmployees.value = employeesList.data;
      }
      flagLoading.value = false;
    });

    return {
      closeModal,
      flagLoading,
      start_date,
      end_date,
      generateTimesheet,
      isMobileView,
      selectForEmployees,
      selectedEmployee,
      validation,
      userType,
    };
  },
};
</script>
  
  <style scoped>
</style>
  