import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { auth } from "./firebase/firebaseConfig.js";
import "./global.css";
import "./index.css";
import VueApexCharts from "vue3-apexcharts";
import "@themesberg/flowbite";
import { useTimerSnapshot } from "@/composables/useTimerSnapshot";
const vuetify = createVuetify({
  components,
  directives,
  theme: {
    themes: {
      light: {
        dark: false,
        variables: {},
        colors: {
          primary: "#176B87",
          secondary: "#86B6F6",
          accent: "#B4D4FF",
          success: "#EEF5FF",
          warning: "#BD4F6C",
        },
      },
    },
  },
});

const app = createApp(App);

app.use(router);
app.use(store);
app.use(vuetify);
app.use(VueApexCharts);

let unsubscribeSnapshot;

const unsubscribeAuth = auth.onAuthStateChanged(async (user) => {
  if (user) {
    const currentUser = auth.currentUser;
    const user = await store.dispatch("fetchResourceInfo", {
      id_user: currentUser.uid,
    });
    await store.commit("setUserMut", {
      uid: currentUser.uid,
      email: currentUser.email,
      type: user && !user.error ? user.data.type : "",
      photo: user && !user.error ? user.data.photo : "",
    });
    await store.dispatch("fetchNotfications", {
      page: 1,
      limit: 100,
      id_user: currentUser.uid,
      search: "",
      types: ["action", "info"],
    });
    store.commit("setAppLoaded", true);

    const { startListening, stopListening } = useTimerSnapshot();

    if (unsubscribeSnapshot) {
      unsubscribeSnapshot();
    }

    startListening();
    unsubscribeSnapshot = stopListening;
  } else {
    if (unsubscribeSnapshot) {
      unsubscribeSnapshot();
    }

    await store.commit("clearUserMut");
    await store.commit("setAppLoaded", true);
  }
});

app.mount("#app");

// Asegura que se desuscriba de la autenticación al destruir la app
app.config.globalProperties.$destroy = unsubscribeAuth;
