import api from "../../api";

export default {
  async fetchTotalHoursWorked({ commit }, params) {
    try {
      const response = await api.get(
        "/new-kambuchi/main-dashboard/total-hours-worked",
        params
      );
      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while fetching total hours worked",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  async fetchTotalActiveProjects({ commit }) {
    try {
      const response = await api.get(
        "/new-kambuchi/main-dashboard/total-active-projects"
      );
      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while fetching total active projects",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  async fetchAproxCost({ commit }, params) {
    try {
      const response = await api.get(
        "/new-kambuchi/main-dashboard/aprox-cost",
        params
      );
      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while fetching aprox cost",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  async fetchTotalAmountBills({ commit }, params) {
    try {
      const response = await api.get(
        "/new-kambuchi/main-dashboard/amount-bills-approved",
        params
      );
      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while fetching total bills generated",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  async fetchUsersWorking({ commit }) {
    try {
      const response = await api.get("/new-kambuchi/main-dashboard/working");
      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while fetching working users",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  async fetchProjectPercentage({ commit }) {
    try {
      const response = await api.get(
        "/new-kambuchi/main-dashboard/project-percentage"
      );
      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while fetching working users",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  async fetchSubmittedTimesheets({ commit }) {
    try {
      const response = await api.get(
        "/new-kambuchi/main-dashboard/submitted-timesheets"
      );
      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while fetching submitted timesheets",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  async fetchSubmittedExpenses({ commit }) {
    try {
      const response = await api.get(
        "/new-kambuchi/main-dashboard/submitted-expenses"
      );
      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while fetching submitted expenses",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  async fetchSubmittedBills({ commit }) {
    try {
      const response = await api.get(
        "/new-kambuchi/main-dashboard/submitted-bills"
      );
      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while fetching submitted bills",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  async fetchTasksByDay({ commit }, params) {
    try {
      const response = await api.get(
        "/new-kambuchi/main-dashboard/charts/completed-tasks-by-day",
        params
      );
      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while fetching tasks by day",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  async fetchApprovedOutgoings({ commit }, params) {
    try {
      const response = await api.get(
        "/new-kambuchi/main-dashboard/charts/approved-outgoings",
        params
      );
      if (response.ok) {
        return response;
      } else {
        commit("setSnackbar", {
          text: "Unexpected error while fetching approved Outgoings",
          enabled: true,
        });
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
};
