<template>
  <LoaderSpinner v-if="flagLoading" />

  <div v-else>
    <!-- Vista de escritorio -->
    <div v-if="!isMobileView" class="flex gap-4 w-full">
      <!-- Columna 1: Tareas -->
      <div class="w-1/2 bg-white rounded-xl border border-slate-200 p-4">
        <div class="text-neutral-900 text-lg font-semibold leading-7 pb-5">
          Next Due Date Tasks
        </div>

        <div
          v-if="tasks.length === 0"
          class="text-slate-600 text-sm font-normal"
        >
          There are no tasks with due date in the next 10 days
        </div>

        <div v-else class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div
            v-for="task in tasks"
            :key="task.id_task"
            class="p-4 bg-slate-50 rounded-lg cursor-pointer"
            @click="openTask(task.id_task)"
          >
            <div class="flex flex-col text-left">
              <div class="text-neutral-900 text-base font-medium">
                Project: <b>{{ task.Task.Project.name }}</b>
              </div>
              <div class="text-neutral-900 text-base font-medium">
                Task: {{ task.Task.name }}
              </div>
              <div class="text-neutral-900 text-base font-medium">
                Description: {{ task.Task.description }}
              </div>
              <div class="text-neutral-900 text-base font-medium">
                Status: {{ task.Task.status }}
              </div>
              <div class="text-slate-900 text-base font-normal">
                Due date: <b>{{ task.Task.due_date }}</b>
              </div>
              
            </div>
          </div>
        </div>
      </div>

      <!-- Columna 2: Gráfico -->
      <div class="w-1/2 bg-white rounded-xl border border-slate-200 p-4">
        <div class="grow shrink basis-0 h-5 flex items-center">
          <div
            class="grow shrink basis-0 h-5 text-neutral-900 text-lg font-semibold font-['Public Sans'] leading-7"
          >
            Hours Worked This Week vs Last Week
          </div>
        </div>
        <div id="chart2">
          <apexchart
            type="bar"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
      </div>
    </div>

    <!-- Vista móvil -->
    <div v-else class="w-full px-4 mt-8">
      <div
        class="text-neutral-900 text-lg font-semibold font-['Public Sans'] leading-7 pb-5"
      >
        Next Due Date Tasks
      </div>

      <div class="w-full bg-white rounded-xl border border-slate-200 p-4">
        <div
          v-if="tasks.length === 0"
          class="text-slate-600 text-sm font-normal"
        >
          There are no tasks with due date in the next 10 days
        </div>

        <div v-else class="space-y-4">
          <div
            v-for="task in tasks"
            :key="task.id_task"
            class="p-4 bg-slate-50 rounded-lg cursor-pointer"
            @click="openTask(task.id_task)"
          >
            <div class="flex flex-col text-left">
              <div class="text-neutral-900 text-base font-medium">
                Project: <b>{{ task.Task.Project.name }}</b>
              </div>
              <div class="text-neutral-900 text-base font-medium">
                Task: {{ task.Task.name }}
              </div>
              <div class="text-neutral-900 text-base font-medium">
                Description: {{ task.Task.description }}
              </div>
              <div class="text-neutral-900 text-base font-medium">
                Status: {{ task.Task.status }}
              </div>
              <div class="text-slate-900 text-base font-normal">
                Due date: <b>{{ task.Task.due_date }}</b>
              </div>
              
            </div>
          </div>
        </div>
      </div>

      <div class="w-full bg-white rounded-xl border border-slate-200 p-4 mt-4">
        <div class="grow shrink basis-0 h-5 flex items-center">
          <div
            class="grow shrink basis-0 h-5 text-neutral-900 text-lg font-semibold font-['Public Sans'] leading-7"
          >
            Hours Worked This Week vs Last Week
          </div>
        </div>
        <div id="chart2">
          <apexchart
            type="bar"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
      </div>
    </div>
  </div>

  <ModalTaskDetails
    v-if="showModalTaskDetail"
    :id_task="idTaskSelected"
    :isOther="flagIsNotActiveTimer"
    :isOpen="showModalTaskDetail"
    @close="showModalTaskDetail = false"
  />
</template>

<script>
import LoaderSpinner from "@/components/CircularLoader.vue";
import ModalTaskDetails from "@/components/Projects/Tasks/TaskDetails.vue";
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import ApexCharts from "vue3-apexcharts";

export default {
  components: {
    LoaderSpinner,
    ModalTaskDetails,
    apexchart: ApexCharts,
  },
  setup() {
    const store = useStore();
    const flagLoading = ref(true);
    const id_user = computed(() => store.state.user.uid);
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const tasks = ref(null);
    const hoursWorkedThisWeek = ref(null);
    const hoursWorkedLastWeek = ref(null);

    const showModalTaskDetail = ref(false);
    const isTimerActive = computed(() => store.getters.isTimerActive);
    const user_type = computed(() => store.state.user.type);
    const flagIsNotActiveTimer = ref(false);
    const selectedTakeaway = computed(() => store.getters.selectedTakeaway);
    const idTaskSelected = ref(null);

    const isMobileView = computed(() => store.getters.isMobileView);

    const series = ref([
      {
        name: "Worked Hours Current vs Last Week",
        data: [0, 0],
      },
    ]);

    const chartOptions = ref({
      chart: {
        type: "bar",
        height: 350,
      },
      xaxis: {
        categories: ["This Week", "Last Week"],
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " hours";
          },
        },
      },
      colors: ["#00225E", "#546E7A"],
    });

    onMounted(async () => {
      try {
        const nddt = await store.dispatch("fetchNextDueDateTasks", {
          id_user: id_user.value,
          userTimezone: userTimezone,
        });
        const hwtw = await store.dispatch("fetchHoursByWeek", {
          id_user: id_user.value,
          userTimezone: userTimezone,
          weekOffset: 0,
        });
        const hwlw = await store.dispatch("fetchHoursByWeek", {
          id_user: id_user.value,
          userTimezone: userTimezone,
          weekOffset: -1,
        });

        tasks.value = nddt.data;
        hoursWorkedThisWeek.value = hwtw.data;
        hoursWorkedLastWeek.value = hwlw.data;

        series.value = [
          {
            name: "Total Hours",
            data: [
              hoursWorkedThisWeek.value.toFixed(2),
              hoursWorkedLastWeek.value.toFixed(2),
            ],
          },
        ];

        flagLoading.value = false;
      } catch (error) {
        console.error("Error in getting projects:", error);
      }
    });

    const openTask = async (id_task) => {
      idTaskSelected.value = id_task;
      console.log("🚀 ~ openTask ~ idTaskSelected:", idTaskSelected.value);
      if (isTimerActive.value && isTimerActive.value.toString() == "true") {
        if (
          idTaskSelected.value.toString() ==
          selectedTakeaway.value.id_task.toString()
        ) {
          toggleModalTaskDetail();
        } else {
          flagIsNotActiveTimer.value = true;
          toggleModalTaskDetail();
        }
      } else {
        if (user_type.value.toString() == "1") {
          flagIsNotActiveTimer.value = true;
        }
        toggleModalTaskDetail();
      }
    };

    function toggleModalTaskDetail() {
      showModalTaskDetail.value = !showModalTaskDetail.value;
    }

    return {
      flagLoading,
      isMobileView,
      tasks,
      series,
      chartOptions,
      showModalTaskDetail,
      flagIsNotActiveTimer,
      openTask,
      idTaskSelected,
    };
  },
};
</script>