<template>
  <div
    v-if="isOpen"
    class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
  >
    <LoaderSpinner v-if="flagLoading" />

    <div
      v-if="!isMobileView"
      class="w-[572px] bg-white rounded-xl shadow flex flex-col"
    >
      <div
        class="p-3 bg-slate-100 rounded-tl-xl rounded-tr-xl border-b border-slate-200 flex justify-between items-center"
      >
        <div class="text-neutral-900 text-lg font-semibold">
          Invoice Details
        </div>
        <button
          @click="closeModal"
          class="p-1 bg-gray-400 rounded-[20px] justify-start items-start gap-2.5 flex"
          style="flex-grow: 0 !important"
        >
          <svg
            class="w-4 h-4 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>
      <div class="p-4 flex flex-col gap-4">
        <div
          class="w-[572px] h-[26px] px-4 justify-between items-center inline-flex"
        >
          <div
            class="flex-col justify-center items-start text-left gap-1 inline-flex"
          >
            <div class="text-slate-500 text-left text-sm font-normal">
              Project
            </div>
            <div
              class="text-neutral-900 text-base font-medium font-['Public Sans'] leading-relaxed"
            >
              {{ data.Project ? data.Project.name : "" }}
            </div>
          </div>
        </div>
        <br />

        <div class="flex justify-between gap-4">
          <div class="flex flex-col">
            <div class="text-slate-500 text-sm text-left font-normal">Date</div>
            <div class="text-neutral-900 text-sm text-left font-semibold">
              {{ helpers.formatDateToDDMMMYYYY(data.date) }}
            </div>
          </div>

          <div class="flex flex-col">
            <div class="text-slate-500 text-left text-sm font-normal">
              Assignee
            </div>
            <div class="flex gap-1 mt-1">
              <img
                class="w-6 h-6 rounded-full"
                :src="data.User ? data.User.photo : ''"
                :title="
                  data.User ? data.User.name + ' ' + data.User.last_name : ''
                "
              />
            </div>
          </div>
        </div>
        <div>
          <div class="text-slate-500 text-left text-sm font-normal">Amount</div>
          <div
            class="text-neutral-900 text-left text-base font-normal leading-relaxed"
          >
            ${{ data.amount }}
          </div>
        </div>
        <div>
          <div class="text-slate-500 text-left text-sm font-normal">Status</div>
          <div
            v-if="data.status == 'Submitted'"
            class="px-3 pt-1 mix-blend-multiply bg-blue-50 rounded-2xl justify-start items-center flex"
          >
            <div
              class="text-center text-blue-700 text-left text-sm font-medium font-['Public Sans'] leading-snug"
            >
              {{ data.status }}
            </div>
          </div>
          <div
            v-if="data.status == 'Approved'"
            class="px-3 pt-1 mix-blend-multiply bg-emerald-50 rounded-2xl justify-start items-center flex"
          >
            <div
              class="text-center text-emerald-700 text-left text-sm font-medium font-['Public Sans'] leading-snug"
            >
              {{ data.status }}
            </div>
          </div>
          <div
            v-if="data.status == 'Rejected'"
            class="px-3 pt-1 mix-blend-multiply bg-red-50 rounded-2xl justify-start items-center flex"
          >
            <div
              class="text-center text-red-700 text-left text-sm font-medium font-['Public Sans'] leading-snug"
            >
              {{ data.status }}
            </div>
          </div>
        </div>
        <div
          v-if="status == 'Submitted' && user_type == 1"
          class="px-4 pt-2 pb-6 justify-start items-center gap-3 inline-flex"
        >
          <button
            @click="rejectInvoice"
            class="px-3.5 py-[9px] bg-red-400 rounded-lg shadow border border-slate-200 justify-center items-center gap-2 flex"
          >
            <div
              class="text-white text-sm font-semibold font-['Public Sans'] leading-snug"
            >
              Reject Invoice
            </div>
          </button>

          <button
            @click="aproveInvoice"
            class="px-3.5 py-[9px] bg-blue_primary rounded-lg shadow justify-center items-center gap-2 flex"
          >
            <div
              class="text-white text-sm font-semibold font-['Public Sans'] leading-snug"
            >
              Aprove Invoice
            </div>
          </button>
        </div>
      </div>
    </div>
    <div
      v-else
      class="w-full max-w-sm bg-white rounded-2xl shadow-lg flex flex-col max-h-[80vh] overflow-y-auto"
    >
      <!-- Encabezado -->
      <div
        class="p-3 bg-slate-100 rounded-t-xl border-b border-slate-200 flex justify-between items-center"
      >
        <div class="text-neutral-900 text-lg font-semibold">
          Invoice Details
        </div>
        <button @click="closeModal" class="p-1 bg-gray-400 rounded-full">
          <svg
            class="w-4 h-4 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>

      <!-- Contenido del modal -->
      <div class="p-4 flex flex-col gap-4">
        <!-- Proyecto -->
        <div class="flex flex-col text-left">
          <div class="text-slate-500 text-sm">Project</div>
          <div class="text-neutral-900 text-base font-medium">
            {{ data.Project ? data.Project.name : "" }}
          </div>
        </div>

        <!-- Fecha y Asignado -->
        <div class="flex justify-between gap-4">
          <div class="flex flex-col text-left">
            <div class="text-slate-500 text-sm">Date</div>
            <div class="text-neutral-900 text-sm font-semibold">
              {{ helpers.formatDateToDDMMMYYYY(data.date) }}
            </div>
          </div>

          <div class="flex flex-col text-left">
            <div class="text-slate-500 text-sm">Assignee</div>
            <div class="mt-1">
              <img
                class="w-8 h-8 rounded-full"
                :src="data.User ? data.User.photo : ''"
                :title="
                  data.User ? data.User.name + ' ' + data.User.last_name : ''
                "
              />
            </div>
          </div>
        </div>

        <!-- Monto -->
        <div class="text-left">
          <div class="text-slate-500 text-sm">Amount</div>
          <div class="text-neutral-900 text-base">${{ data.amount }}</div>
        </div>

        <!-- Estado -->
        <div class="text-left">
          <div class="text-slate-500 text-sm">Status</div>
          <div
            v-if="data.status == 'Submitted'"
            class="px-3 py-1 bg-blue-50 rounded-2xl text-blue-700 text-sm font-medium inline-block"
          >
            {{ data.status }}
          </div>
          <div
            v-else-if="data.status == 'Approved'"
            class="px-3 py-1 bg-emerald-50 rounded-2xl text-emerald-700 text-sm font-medium inline-block"
          >
            {{ data.status }}
          </div>
          <div
            v-else-if="data.status == 'Rejected'"
            class="px-3 py-1 bg-red-50 rounded-2xl text-red-700 text-sm font-medium inline-block"
          >
            {{ data.status }}
          </div>
        </div>

        <!-- Botones de acción -->
        <div
          v-if="status == 'Submitted' && user_type == 1"
          class="flex flex-col gap-2 mt-4"
        >
          <button
            @click="rejectInvoice"
            class="w-full py-2 bg-red-400 text-white rounded-lg shadow"
          >
            Reject Invoice
          </button>
          <button
            @click="aproveInvoice"
            class="w-full py-2 bg-blue-500 text-white rounded-lg shadow"
          >
            Approve Invoice
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import LoaderSpinner from "../../CircularLoader.vue";
import helpers from "../../../helpers/index.js";

export default {
  components: {
    LoaderSpinner,
  },
  props: {
    isOpen: Boolean,
    id_invoice: String,
    status: String,
  },
  emits: ["close"],

  setup(props, { emit }) {
    const store = useStore();

    const flagLoading = ref(true);
    const data = ref("");
    const user_type = computed(() => store.state.user.type);
    const user_id = computed(() => store.state.user.uid);
    const isMobileView = computed(() => store.getters.isMobileView);
    function closeModal() {
      emit("close");
    }

    async function aproveInvoice() {
      flagLoading.value = true;
      const data = {
        id_invoice: props.id_invoice,
        status: "Approved",
        changedBy: user_id.value,
      };
      const resp = await store.dispatch("updateInvoiceStatus", data);

      if (resp.ok) {
        await store.commit("setSnackbar", {
          text: resp.message,
          enabled: true,
        });
        flagLoading.value = false;
        closeModal();
      }
    }

    async function rejectInvoice() {
      flagLoading.value = true;
      const data = {
        id_invoice: props.id_invoice,
        status: "Rejected",
        changedBy: user_id.value,
      };
      const resp = await store.dispatch("updateInvoiceStatus", data);

      if (resp.ok) {
        await store.commit("setSnackbar", {
          text: resp.message,
          enabled: true,
        });
        flagLoading.value = false;
        closeModal();
      }
    }

    onMounted(async () => {
      await store
        .dispatch("fetchInvoice", { id_invoice: props.id_invoice })
        .then((dataFetched) => {
          data.value = dataFetched;
        });

      flagLoading.value = false;
    });

    return {
      closeModal,
      user_type,
      flagLoading,
      data,
      helpers,
      aproveInvoice,
      rejectInvoice,
      isMobileView,
    };
  },
};
</script>
  
  <style scoped>
/* Add necessary styles */
</style>
  