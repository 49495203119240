<template>
  <div
    class="w-72 bg-blue_primary border-r flex flex-col fixed h-screen overflow-y-auto"
  >
    <div
      class="self-stretch flex-col justify-start items-center gap-6 inline-flex"
    >
      <div
        class="w-64 h-[60px] border-b border-white/opacity-50 justify-center items-start gap-10 inline-flex"
      >
        <div
          class="w-60 h-[60px] px-5 pb-3 py-2 justify-center items-start gap-2.5 flex pointer-events-auto cursor-pointer"
          @click="home"
        >
          <img class="w-15 h-12" src="/kamvu/logox4.png" />
        </div>
      </div>
      <div
        class="self-stretch px-4 flex-col justify-start items-start gap-2 flex"
      >
        <div v-for="item in filteredNavItems" :key="item.text" class="w-full">
          <div
            :class="
              selectedNavItem === item.text ? 'nav-item selected' : 'nav-item'
            "
            @click="handleClick(item)"
            class="flex items-center gap-2 justify-between"
          >
            <div class="flex items-center gap-2">
              <img
                :class="[
                  'icon',
                  { 'selected-icon': selectedNavItem === item.text },
                ]"
                :src="item.icon"
                :alt="`${item.text} Icon`"
              />
              <button
                :class="
                  selectedNavItem === item.text
                    ? 'text-left w-full text-sm font-normal leading-snug text-whitefont-semibold'
                    : 'text-left w-full text-sm font-normal leading-snug text-white'
                "
              >
                {{ item.text }}
              </button>
            </div>
            <div v-if="item.subItems" class="icon-dropdown">
              <img
                src="/icons/side-bar/dashboard.svg"
                :class="
                  expandedNavItem === item.text
                    ? 'pointer-events-auto cursor-pointer'
                    : 'rotate-180 pointer-events-auto cursor-pointer'
                "
                class="w-4 h-4 transition-transform duration-300"
              />
            </div>
          </div>
          <div v-if="item.subItems && expandedNavItem === item.text">
            <div
              v-for="subItem in item.subItems"
              :key="subItem.text"
              class="nav-item pl-8 flex items-center gap-2"
              :class="selectedNavItem === subItem.text ? 'selected' : ''"
              @click="handleSubItemClick(subItem)"
            >
              <img
                :src="subItem.icon"
                :alt="`${subItem.text} Icon`"
                class="icon"
                :class="selectedNavItem === subItem.text ? 'selected-icon' : ''"
              />
              <button
                class="text-left w-full text-sm font-normal leading-snug text-white"
              >
                {{ subItem.text }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const selectedNavItem = ref("");
    const expandedNavItem = ref("");

    const userRole = computed(() => {
      if (store.state.user) {
        return store.state.user.type;
      }
      return null;
    });

    const navItems = [
      { text: "Dashboard", icon: "/icons/side-bar/dashboard.svg" },
      {
        text: "Client Management",
        icon: "/icons/side-bar/client-management.svg",
      },
      {
        text: "Resource Management",
        icon: "/icons/side-bar/user-management.svg",
      },
      {
        text: "Project Management",
        icon: "/icons/side-bar/projects.svg",
        subItems: [
          { text: "Timesheets", icon: "/icons/side-bar/timesheets.svg" },
          { text: "Invoices", icon: "/icons/side-bar/invoices.svg" },
          { text: "Expenses", icon: "/icons/side-bar/expenses.svg" },
          { text: "Bills", icon: "/icons/side-bar/expenses.svg" },
        ],
      },
      { text: "Project Invoice", icon: "/icons/side-bar/projects.svg" },
      { text: "Reports", icon: "/icons/side-bar/reports.svg" },
      { text: "Account Settings", icon: "/icons/side-bar/settings.svg" },
    ];

    const filteredNavItems = computed(() => {
      const items = JSON.parse(JSON.stringify(navItems));

      if (userRole.value && userRole.value == 1) {
        return items;
      }

      const filteredItems = items.filter(
        (item) =>
          item.text !== "Resource Management" &&
          item.text !== "Client Management"
      );

      filteredItems.forEach((item) => {
        if (item.text === "Project Management" && item.subItems) {
          item.subItems = item.subItems.filter((subItem) => {
            if (userRole.value === 2) {
              return subItem.text !== "Bills" && subItem.text !== "Invoices";
            } else if (userRole.value === 3) {
              return (
                subItem.text !== "Timesheets" && subItem.text !== "Invoices"
              );
            }
            return subItem.text !== "Invoices";
          });
        }
      });

      return filteredItems;
    });

    const handleClick = (item) => {
      selectedNavItem.value = item.text;
      if (item.subItems) {
        expandedNavItem.value =
          expandedNavItem.value === item.text ? "" : item.text;
      } else {
        expandedNavItem.value = "";
      }
      switch (item.text) {
        case "Dashboard":
          router.push({ name: "dashboard" });
          break;
        case "Project Management":
          router.push({ name: "projects" });
          break;
        case "Resource Management":
          router.push({ name: "resource-management" });
          break;
        case "Client Management":
          router.push({ name: "client-management" });
          break;
        case "Account Settings":
          router.push({ name: "account-settings" });
          break;
        default:
          break;
      }
    };

    const handleSubItemClick = (subItem) => {
      selectedNavItem.value = subItem.text;
      switch (subItem.text) {
        case "Timesheets":
          router.push({ name: "timesheets" });
          break;
        case "Expenses":
          router.push({ name: "expenses" });
          break;
        case "Invoices":
          router.push({ name: "invoices" });
          break;
        case "Bills":
          router.push({ name: "bills" });
          break;
        default:
          break;
      }
    };

    const home = () => {
      router.push({ name: "dashboard" });
    };

    return {
      filteredNavItems,
      handleClick,
      handleSubItemClick,
      selectedNavItem,
      expandedNavItem,
      userRole,
      home,
    };
  },
};
</script>

<style scoped>
.nav-item {
  width: 100%;
  padding: 9px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.3s, color 0.3s;
}

.nav-item:hover,
.nav-item.selected {
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
}

.icon {
  width: 16px;
  height: 16px;
  transition: filter 0.3s;
}

.selected-icon,
.nav-item.selected .icon {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%)
    hue-rotate(0deg) brightness(100%) contrast(100%);
}

.icon-dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-dropdown .rotate-180 {
  transform: rotate(180deg);
}

.text-white {
  color: white;
}

.text-blue-700 {
  color: #1d4ed8;
}

.font-semibold {
  font-weight: 600;
}

.text-left {
  text-align: left;
}
</style>
