<template>
  <LoaderSpinner v-if="flagLoading" />
  <div
    v-else-if="!isMobileView"
    class="w-full h-auto p-5 bg-white rounded-xl border border-slate-200 flex flex-col gap-4"
  >   
    <div
      class="w-full h-auto bg-white rounded-lg shadow dark:bg-gray-800 p-4 md:p-6"
    >
      <div class="self-stretch flex justify-between items-center">
        <div class="grow shrink basis-0 h-5 flex items-center">
          <div
            class="grow shrink basis-0 h-5 text-neutral-900 text-lg font-semibold font-['Public Sans'] leading-7"
          >
            Completed Tasks By Day
          </div>
        </div>
        <div
          class="pl-3.5 pr-2.5 py-1.5 rounded-lg border border-slate-200 flex justify-center items-center gap-2"
        >
          <div
            class="text-slate-600 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            Weekly
          </div>
          <div class="w-5 h-5 relative"></div>
        </div>
      </div>
      <div id="chart">
        <apexchart
          type="area"
          height="350"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
    </div>

    <div
      class="w-full h-auto bg-white rounded-lg shadow dark:bg-gray-800 p-4 md:p-6"
    >
      <div class="self-stretch flex justify-between items-center mb-4">
        <div class="grow shrink basis-0 h-5 flex items-center">
          <div
            class="grow shrink basis-0 h-5 text-neutral-900 text-lg font-semibold font-['Public Sans'] leading-7"
          >
            Cost By Project
          </div>
        </div>
      </div>
      <div id="chart2">
        <apexchart
          type="donut"
          height="350"
          :options="chartDonutOptions"
          :series="seriesDonut"
        ></apexchart>
      </div>
    </div>
  </div>

  <div
    v-else
    class="w-full h-auto p-5 bg-white rounded-xl border border-slate-200 flex flex-col gap-4"
  >
    <div class="w-full bg-white rounded-lg shadow dark:bg-gray-800 p-4 md:p-6">
      <div class="self-stretch flex justify-between items-center">
        <div class="grow shrink basis-0 h-5 flex items-center">
          <div
            class="grow shrink basis-0 h-5 text-neutral-900 text-lg font-semibold font-['Public Sans'] leading-7"
          >
            Timesheet Analysis
          </div>
        </div>
        <div
          class="pl-3.5 pr-2.5 py-1.5 rounded-lg border border-slate-200 flex justify-center items-center gap-2"
        >
          <div
            class="text-slate-600 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            Weekly
          </div>
          <div class="w-5 h-5 relative"></div>
        </div>
      </div>
      <div id="chart">
        <apexchart
          type="area"
          height="350"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
    </div>

    <div class="w-full bg-white rounded-lg shadow dark:bg-gray-800 p-4 md:p-6">
      <div class="self-stretch flex justify-between items-center mb-4">
        <div class="grow shrink basis-0 h-5 flex items-center">
          <div
            class="grow shrink basis-0 h-5 text-neutral-900 text-lg font-semibold font-['Public Sans'] leading-7"
          >
            Cost By Project
          </div>
        </div>
      </div>
      <div id="chart2">
        <apexchart
          type="donut"
          :options="chartDonutOptions"
          :series="seriesDonut"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import ApexCharts from "vue3-apexcharts";
import LoaderSpinner from "@/components/CircularLoader.vue";

export default {
  components: {
    apexchart: ApexCharts,
    LoaderSpinner,
  },
  setup() {
    const store = useStore();
    const isMobileView = computed(() => store.getters.isMobileView);
    console.log("🚀 ~ setup ~ isMobileView:", isMobileView.value)
    const flagLoading = ref(true);

    const series = ref([
      {
        name: "Completed Tasks",
        data: [0, 0, 0, 0, 0, 0, 0],
      },
    ]);

   
    const chartOptions = ref({
      chart: {
        type: "area",
        height: 350,
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      labels: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      xaxis: {
        type: "category",
      },
      yaxis: {
        opposite: false,
      },
    });

    const seriesDonut = ref([]);
    const chartDonutOptions = ref({
      chart: {
        type: "donut",
      },
      labels: [],
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          offsetY: 10,
        },
      },
      grid: {
        padding: {
          bottom: -80,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    });

    onMounted(async () => {
      try {
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const params = {
          timezone: userTimezone,
          weekOffset: 0,
        };

        const responseTasks = await store.dispatch("fetchTasksByDay", params);
        if (responseTasks.ok) {
          const tasksByDay = responseTasks.data;
          const daysOfWeek = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ];
          series.value[0].data = daysOfWeek.map((day) => tasksByDay[day] || 0);
        } else {
          console.error("Error fetching tasks by day:", responseTasks.message);
        }

        const responseApprovedOutgoings = await store.dispatch(
          "fetchApprovedOutgoings",
          params
        );

        if (responseApprovedOutgoings.ok && responseApprovedOutgoings.data) {
          const approvedOutgoingsData = responseApprovedOutgoings.data;

          const labels = Object.keys(approvedOutgoingsData);
          const values = Object.values(approvedOutgoingsData).map(parseFloat);

          seriesDonut.value = values;
          chartDonutOptions.value.labels = labels;
        } else {
          console.warn("No approved outgoings data available.");
          seriesDonut.value = [];
          chartDonutOptions.value.labels = [];
        }
      } catch (error) {
        console.error("Error in onMounted:", error);
      } finally {
        flagLoading.value = false;
      }
    });

    return {
      series,
      chartOptions,
      seriesDonut,
      chartDonutOptions,
      isMobileView,
      flagLoading,
    };
  },
};
</script>