import store from "@/store";

export default async function authMiddleware(to, from, next) {
  if (!store.state.appLoaded) {
    return next(false);
  }

  const isAuthenticated = store.state.loggedUser;
  const userType = store.state.user ? store.state.user.type : null;
  const userId = store.state.user ? store.state.user.uid : null;

  const publicRoutes = [
    "login",
    "register",
    "forgot-password",
    "register-resource",
    "register-owner",
  ];

  if (publicRoutes.includes(to.name)) {
    return next();
  }

  if (!isAuthenticated) {
    return next({ name: "login" });
  }

  if (userType === 1) {
    return next();
  } else if (userType === 2 || userType === 3) {
    const restrictedRoutes = [
      "create-new-project",
      "edit-client",
      "create-new-resource",
      "edit-resource",
      "resource-management",
      "client-management",
      "client-desc",
      "edit-project"
    ];
    if (to.name === "edit-resource" && to.params.id === userId) {
      return next();
    }
    if (restrictedRoutes.includes(to.name)) {
      return next({ name: "dashboard" });
    }
  }

  next();
}
