<template>
  <LoaderSpinner v-if="flagLoading" />
  <div v-else-if="!isMobileView">
    <div class="w-full px-4 pb-5">
      <div
        class="text-neutral-900 text-lg font-semibold font-['Public Sans'] leading-7"
      >
        Overview
      </div>
    </div>

    <div class="w-full px-4 grid grid-cols-2 gap-4">
      <!-- Card 1: Weekly Hours Worked -->
      <div class="p-4 bg-white rounded-xl border border-slate-200">
        <div
          class="flex items-center justify-between text-slate-600 text-base font-normal font-['Public Sans'] leading-relaxed"
        >
          <span>Weekly Hours Worked</span>
          <img
            src="/icons/projects/action-dots.svg"
            class="w-5 h-5 cursor-pointer"
          />
        </div>
        <div class="mt-4 flex justify-between items-end">
          <div>
            <span
              class="text-neutral-900 text-4xl font-medium font-['Public Sans'] leading-10"
            >
              {{ totalHoursWorked.toFixed(2) }}
            </span>
            <span
              class="text-neutral-900 text-base font-medium font-['Public Sans'] leading-10"
            >
              hours
            </span>
          </div>
          <img
            src="/icons/dashboard/chart.svg"
            v-if="(100 - lastWeekPercentHoursWorked).toFixed(2) < 0"
          />
          <img src="/icons/dashboard/chart-down.svg" v-else />
        </div>
        <div class="mt-2 flex items-center gap-1">
          <img
            src="/icons/dashboard/up.svg"
            class="w-5 h-5"
            v-if="(100 - lastWeekPercentHoursWorked).toFixed(2) < 0"
          />
          <img src="/icons/dashboard/down.svg" class="w-5 h-5" v-else />
          <span
            class="text-blue-700 text-sm font-medium font-['League Spartan'] leading-[18px]"
          >
            {{
              (100 - lastWeekPercentHoursWorked).toFixed(2) < 0
                ? -1 * (100 - lastWeekPercentHoursWorked).toFixed(2)
                : (100 - lastWeekPercentHoursWorked).toFixed(2)
            }}%
          </span>
          <span
            class="text-slate-600 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            last week
          </span>
        </div>
      </div>

      <!-- Card 2: Total Timesheets Generated -->
      <div class="p-4 bg-white rounded-xl border border-slate-200">
        <div
          class="flex items-center justify-between text-slate-600 text-base font-normal font-['Public Sans'] leading-relaxed"
        >
          <span
            >Aprox Cost Until
            {{
              new Date().toLocaleDateString(locale, { weekday: "long" })
            }}</span
          >
          <img
            src="/icons/projects/action-dots.svg"
            class="w-5 h-5 cursor-pointer"
          />
        </div>
        <div class="mt-4 flex justify-between items-end">
          <div>
            <span
              class="text-neutral-900 text-4xl font-medium font-['Public Sans'] leading-10"
            >
              ${{ aproxCost.toFixed(2) }}
            </span>
          </div>
          <img
            src="/icons/dashboard/chart.svg"
            v-if="(100 - aproxCostLastWeek).toFixed(2) < 0"
          />
          <img src="/icons/dashboard/chart-down.svg" v-else />
        </div>
        <div class="mt-2 flex items-center gap-1">
          <img
            src="/icons/dashboard/up.svg"
            class="w-5 h-5"
            v-if="(100 - aproxCostLastWeek).toFixed(2) < 0"
          />
          <img src="/icons/dashboard/down.svg" class="w-5 h-5" v-else />
          <span
            class="text-blue-700 text-sm font-medium font-['League Spartan'] leading-[18px]"
          >
            {{
              (100 - aproxCostLastWeek).toFixed(2) < 0
                ? -1 * (100 - aproxCostLastWeek).toFixed(2)
                : (100 - aproxCostLastWeek).toFixed(2)
            }}%
          </span>
          <span
            class="text-slate-600 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            last week
          </span>
        </div>
      </div>

      <!-- Card 3: Total Amount Bills -->
      <div class="p-4 bg-white rounded-xl border border-slate-200">
        <div
          class="flex items-center justify-between text-slate-600 text-base font-normal font-['Public Sans'] leading-relaxed"
        >
          <span>Weekly Amount Bills</span>
          <img
            src="/icons/projects/action-dots.svg"
            class="w-5 h-5 cursor-pointer"
          />
        </div>
        <div class="mt-4 flex justify-between items-end">
          <div>
            <span
              class="text-neutral-900 text-4xl font-medium font-['Public Sans'] leading-10"
            >
              ${{ totalAmountBills }}
            </span>
          </div>
          <img
            src="/icons/dashboard/chart.svg"
            v-if="(100 - totalBillsLastWeek).toFixed(2) < 0"
          />
          <img src="/icons/dashboard/chart-down.svg" v-else />
        </div>
        <div class="mt-2 flex items-center gap-1">
          <img
            src="/icons/dashboard/up.svg"
            class="w-5 h-5"
            v-if="(100 - totalBillsLastWeek).toFixed(2) < 0"
          />
          <img src="/icons/dashboard/down.svg" class="w-5 h-5" v-else />
          <span
            class="text-blue-700 text-sm font-medium font-['League Spartan'] leading-[18px]"
          >
            {{
              (100 - totalBillsLastWeek).toFixed(2) < 0
                ? -1 * (100 - totalBillsLastWeek).toFixed(2)
                : (100 - totalBillsLastWeek).toFixed(2)
            }}%
          </span>
          <span
            class="text-slate-600 text-sm font-normal font-['Public Sans'] leading-snug"
          >
            last week
          </span>
        </div>
      </div>

      <!-- Card 5: Submitted Timesheets -->
      <div class="p-4 bg-white rounded-xl border border-slate-200">
        <div
          class="flex items-center justify-between text-slate-600 text-base font-normal font-['Public Sans'] leading-relaxed"
        >
          <span>Submitted Timesheets</span>
          <img
            src="/icons/projects/action-dots.svg"
            class="w-5 h-5 cursor-pointer"
          />
        </div>
        <div
          class="mt-4 flex justify-between items-end cursor-pointer"
          @click="goToSubmittedTimesheets"
        >
          <div>
            <span
              class="text-neutral-900 text-4xl font-medium font-['Public Sans'] leading-10"
            >
              {{ submittedTimesheets }}
            </span>
          </div>
        </div>
      </div>

      <!-- Card 6: Submitted Expenses -->
      <div class="p-4 bg-white rounded-xl border border-slate-200">
        <div
          class="flex items-center justify-between text-slate-600 text-base font-normal font-['Public Sans'] leading-relaxed"
        >
          <span>Submitted Expenses</span>
          <img
            src="/icons/projects/action-dots.svg"
            class="w-5 h-5 cursor-pointer"
          />
        </div>
        <div
          class="mt-4 flex justify-between items-end cursor-pointer"
          @click="goToSubmittedExpenses"
        >
          <div>
            <span
              class="text-neutral-900 text-4xl font-medium font-['Public Sans'] leading-10"
            >
              {{ submittedExpenses }}
            </span>
          </div>
        </div>
      </div>

      <!-- Card 7: Submitted Bills -->
      <div class="p-4 bg-white rounded-xl border border-slate-200">
        <div
          class="flex items-center justify-between text-slate-600 text-base font-normal font-['Public Sans'] leading-relaxed"
        >
          <span>Submitted Bills</span>
          <img
            src="/icons/projects/action-dots.svg"
            class="w-5 h-5 cursor-pointer"
          />
        </div>
        <div
          class="mt-4 flex justify-between items-end cursor-pointer"
          @click="goToSubmittedBills"
        >
          <div>
            <span
              class="text-neutral-900 text-4xl font-medium font-['Public Sans'] leading-10"
            >
              {{ submittedBills }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else>
    <div
      class="w-full h-7 px-4 justify-start items-center gap-[1123px] inline-flex"
    >
      <div
        class="text-neutral-900 text-lg font-semibold font-['Public Sans'] leading-7 pb-5"
      >
        Overview
      </div>
    </div>

    <div class="w-full overflow-x-auto snap-x snap-mandatory flex gap-4 px-4">
      <!-- Card 1: Weekly Hours Worked -->
      <div
        class="min-w-[90%] p-4 bg-white rounded-xl border border-slate-200 flex-col justify-start items-start gap-5 inline-flex snap-center"
      >
        <div
          class="w-full flex items-center justify-between text-slate-600 text-base font-normal font-['Public Sans'] leading-relaxed"
        >
          <span>Weekly Hours Worked</span>
          <img
            src="/icons/projects/action-dots.svg"
            class="w-5 h-5 pointer-events-auto cursor-pointer"
          />
        </div>
        <div class="self-stretch justify-start items-end gap-4 inline-flex">
          <div
            class="w-[105px] flex-col justify-start items-start gap-4 inline-flex"
          >
            <div class="w-[124px]">
              <span
                class="text-neutral-900 text-4xl font-medium font-['Public Sans'] leading-10"
              >
                {{ totalHoursWorked.toFixed(2) }}
              </span>
              <span
                class="text-neutral-900 text-base font-medium font-['Public Sans'] leading-10"
              >
                hours
              </span>
            </div>
            <div
              class="self-stretch h-[50px] flex-col justify-center items-start gap-2 flex"
            >
              <div class="justify-center items-center gap-0.5 inline-flex">
                <img
                  src="/icons/dashboard/up.svg"
                  class="w-5 h-5"
                  v-if="(100 - lastWeekPercentHoursWorked).toFixed(2) < 0"
                />
                <img src="/icons/dashboard/down.svg" class="w-5 h-5" v-else />
                <div
                  class="text-center text-blue-700 text-sm font-medium font-['League Spartan'] leading-[18px]"
                >
                  {{
                    (100 - lastWeekPercentHoursWorked).toFixed(2) < 0
                      ? -1 * (100 - lastWeekPercentHoursWorked).toFixed(2)
                      : (100 - lastWeekPercentHoursWorked).toFixed(2)
                  }}%
                </div>
              </div>
              <div
                class="self-stretch text-slate-600 text-sm font-normal font-['Public Sans'] leading-snug"
              >
                last week
              </div>
            </div>
          </div>
          <img
            src="/icons/dashboard/chart.svg"
            v-if="(100 - lastWeekPercentHoursWorked).toFixed(2) < 0"
          />
          <img src="/icons/dashboard/chart-down.svg" v-else />
        </div>
      </div>

      <div
        class="min-w-[90%] p-4 bg-white rounded-xl border border-slate-200 flex-col justify-start items-start gap-5 inline-flex snap-center"
      >
        <div
          class="w-full flex items-center justify-between text-slate-600 text-base font-normal font-['Public Sans'] leading-relaxed"
        >
          <span
            >Aprox Cost Until
            {{
              new Date().toLocaleDateString(locale, { weekday: "long" })
            }}</span
          >
          <img
            src="/icons/projects/action-dots.svg"
            class="w-5 h-5 pointer-events-auto cursor-pointer"
          />
        </div>
        <div class="self-stretch justify-start items-end gap-4 inline-flex">
          <div
            class="w-[105px] flex-col justify-start items-start gap-4 inline-flex"
          >
            <div class="w-[124px]">
              <span
                class="text-neutral-900 text-4xl font-medium font-['Public Sans'] leading-10"
              >
                ${{ aproxCost.toFixed(2) }}
              </span>
            </div>
            <div
              class="self-stretch h-[50px] flex-col justify-center items-start gap-2 flex"
            >
              <div class="justify-center items-center gap-0.5 inline-flex">
                <img
                  src="/icons/dashboard/up.svg"
                  class="w-5 h-5"
                  v-if="(100 - aproxCostLastWeek).toFixed(2) < 0"
                />
                <img src="/icons/dashboard/down.svg" class="w-5 h-5" v-else />
                <div
                  class="text-center text-blue-700 text-sm font-medium font-['League Spartan'] leading-[18px]"
                >
                  {{
                    (100 - aproxCostLastWeek).toFixed(2) < 0
                      ? -1 * (100 - aproxCostLastWeek).toFixed(2)
                      : (100 - aproxCostLastWeek).toFixed(2)
                  }}%
                </div>
              </div>
              <div
                class="self-stretch text-slate-600 text-sm font-normal font-['Public Sans'] leading-snug"
              >
                last week
              </div>
            </div>
          </div>
          <img
            src="/icons/dashboard/chart.svg"
            v-if="(100 - aproxCostLastWeek).toFixed(2) < 0"
          />
          <img src="/icons/dashboard/chart-down.svg" v-else />
        </div>
      </div>

      <div
        class="min-w-[90%] p-4 bg-white rounded-xl border border-slate-200 flex-col justify-start items-start gap-5 inline-flex snap-center"
      >
        <div
          class="w-full flex items-center justify-between text-slate-600 text-base font-normal font-['Public Sans'] leading-relaxed"
        >
          <span>Weekly Amount Bills</span>
          <img
            src="/icons/projects/action-dots.svg"
            class="w-5 h-5 pointer-events-auto cursor-pointer"
          />
        </div>
        <div class="self-stretch justify-start items-end gap-4 inline-flex">
          <div
            class="w-[105px] flex-col justify-start items-start gap-4 inline-flex"
          >
            <div class="w-[124px]">
              <span
                class="text-neutral-900 text-4xl font-medium font-['Public Sans'] leading-10"
              >
                ${{ totalAmountBills }}
              </span>
            </div>
            <div
              class="self-stretch h-[50px] flex-col justify-center items-start gap-2 flex"
            >
              <div class="justify-center items-center gap-0.5 inline-flex">
                <img
                  src="/icons/dashboard/up.svg"
                  class="w-5 h-5"
                  v-if="(100 - totalBillsLastWeek).toFixed(2) < 0"
                />
                <img src="/icons/dashboard/down.svg" class="w-5 h-5" v-else />
                <div
                  class="text-center text-blue-700 text-sm font-medium font-['League Spartan'] leading-[18px]"
                >
                  {{
                    (100 - totalBillsLastWeek).toFixed(2) < 0
                      ? -1 * (100 - totalBillsLastWeek).toFixed(2)
                      : (100 - totalBillsLastWeek).toFixed(2)
                  }}%
                </div>
              </div>
              <div
                class="self-stretch text-slate-600 text-sm font-normal font-['Public Sans'] leading-snug"
              >
                last week
              </div>
            </div>
          </div>
          <img
            src="/icons/dashboard/chart.svg"
            v-if="(100 - totalBillsLastWeek).toFixed(2) < 0"
          />
          <img src="/icons/dashboard/chart-down.svg" v-else />
        </div>
      </div>

      <div
        @click="goToSubmittedTimesheets"
        class="min-w-[90%] p-4 bg-white rounded-xl border border-slate-200 flex-col justify-start items-start gap-5 inline-flex snap-center"
      >
        <div
          class="w-full flex items-center justify-between text-slate-600 text-base font-normal font-['Public Sans'] leading-relaxed"
        >
          <span>Submitted Timesheets</span>
          <img
            src="/icons/projects/action-dots.svg"
            class="w-5 h-5 pointer-events-auto cursor-pointer"
          />
        </div>
        <div class="self-stretch justify-start items-end gap-4 inline-flex">
          <div
            class="w-[105px] flex-col justify-start items-start gap-4 inline-flex"
          >
            <div class="w-[124px]">
              <span
                class="text-neutral-900 text-4xl font-medium font-['Public Sans'] leading-10"
              >
                {{ submittedTimesheets }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        @click="goToSubmittedExpenses"
        class="min-w-[90%] p-4 bg-white rounded-xl border border-slate-200 flex-col justify-start items-start gap-5 inline-flex snap-center"
      >
        <div
          class="w-full flex items-center justify-between text-slate-600 text-base font-normal font-['Public Sans'] leading-relaxed"
        >
          <span>Submitted Expenses</span>
          <img
            src="/icons/projects/action-dots.svg"
            class="w-5 h-5 pointer-events-auto cursor-pointer"
          />
        </div>
        <div class="self-stretch justify-start items-end gap-4 inline-flex">
          <div
            class="w-[105px] flex-col justify-start items-start gap-4 inline-flex"
          >
            <div class="w-[124px]">
              <span
                class="text-neutral-900 text-4xl font-medium font-['Public Sans'] leading-10"
              >
                {{ submittedExpenses }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        @click="goToSubmittedBills"
        class="min-w-[90%] p-4 bg-white rounded-xl border border-slate-200 flex-col justify-start items-start gap-5 inline-flex snap-center"
      >
        <div
          class="w-full flex items-center justify-between text-slate-600 text-base font-normal font-['Public Sans'] leading-relaxed"
        >
          <span>Submitted Bills</span>
          <img
            src="/icons/projects/action-dots.svg"
            class="w-5 h-5 pointer-events-auto cursor-pointer"
          />
        </div>
        <div class="self-stretch justify-start items-end gap-4 inline-flex">
          <div
            class="w-[105px] flex-col justify-start items-start gap-4 inline-flex"
          >
            <div class="w-[124px]">
              <span
                class="text-neutral-900 text-4xl font-medium font-['Public Sans'] leading-10"
              >
                {{ submittedBills }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoaderSpinner from "@/components/CircularLoader.vue";
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  components: {
    LoaderSpinner,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const flagLoading = ref(true);
    const totalHoursWorked = ref(null);
    const totalActiveProjects = ref(null);
    const aproxCost = ref(null);
    const totalAmountBills = ref(null);
    const submittedTimesheets = ref(null);
    const submittedExpenses = ref(null);
    const submittedBills = ref(null);
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const lastWeekPercentHoursWorked = ref(null);
    const aproxCostLastWeek = ref(null);
    const totalBillsLastWeek = ref(null);

    const isMobileView = computed(() => store.getters.isMobileView);

    const goToSubmittedTimesheets = async () => {
      router.push({ name: `timesheets` });
    };

    const goToSubmittedExpenses = async () => {
      router.push({ name: `expenses` });
    };

    const goToSubmittedBills = async () => {
      router.push({ name: `bills` });
    };

    onMounted(async () => {
      try {
        const thw = await store.dispatch(`fetchTotalHoursWorked`, {
          weekOffset: 0,
          userTimezone: userTimezone,
        });
        const thwLastWeek = await store.dispatch(`fetchTotalHoursWorked`, {
          weekOffset: -1,
          userTimezone: userTimezone,
        });
        if (thwLastWeek.totalHours !== 0) {
          lastWeekPercentHoursWorked.value =
            (parseFloat(thw.totalHours) * 100) /
            parseFloat(thwLastWeek.totalHours);
        } else {
          lastWeekPercentHoursWorked.value = 0;
        }

        const tap = await store.dispatch(`fetchTotalActiveProjects`);

        const ac = await store.dispatch(`fetchAproxCost`, {
          weekOffset: 0,
          userTimezone: userTimezone,
        });
        const acLastWeek = await store.dispatch(`fetchAproxCost`, {
          weekOffset: -1,
          userTimezone: userTimezone,
        });
        if (acLastWeek.data !== 0) {
          aproxCostLastWeek.value =
            (parseFloat(ac.data) * 100) / parseFloat(acLastWeek.data);
        } else {
          aproxCostLastWeek.value = 0;
        }

        const tbg = await store.dispatch(`fetchTotalAmountBills`, {
          weekOffset: 0,
          userTimezone: userTimezone,
        });
        const tbgLastWeek = await store.dispatch(`fetchTotalAmountBills`, {
          weekOffset: -1,
          userTimezone: userTimezone,
        });
        if (tbgLastWeek.data !== 0) {
          totalBillsLastWeek.value =
            (parseFloat(tbg.data) * 100) / parseFloat(tbgLastWeek.data);
        } else {
          totalBillsLastWeek.value = 0;
        }

        const st = await store.dispatch(`fetchSubmittedTimesheets`);
        const se = await store.dispatch(`fetchSubmittedExpenses`);
        const sb = await store.dispatch(`fetchSubmittedBills`);

        totalActiveProjects.value = tap.data;
        totalHoursWorked.value = thw.totalHours;
        aproxCost.value = ac.data;
        totalAmountBills.value = tbg.data;
        submittedTimesheets.value = st.data;
        submittedExpenses.value = se.data;
        submittedBills.value = sb.data;

        flagLoading.value = false;
      } catch (error) {
        console.error("Error in getting projects:", error);
      }
    });

    return {
      flagLoading,
      totalHoursWorked,
      totalActiveProjects,
      aproxCost,
      totalAmountBills,
      submittedTimesheets,
      submittedExpenses,
      submittedBills,
      goToSubmittedTimesheets,
      goToSubmittedExpenses,
      goToSubmittedBills,
      isMobileView,
      lastWeekPercentHoursWorked,
      aproxCostLastWeek,
      totalBillsLastWeek,
    };
  },
};
</script>

<style scoped>
.carrusel-container {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  gap: 16px;
  padding: 0 16px;
}

.carrusel-card {
  min-width: 90%;
  scroll-snap-align: center;
  flex-shrink: 0;
}

.carrusel-container::-webkit-scrollbar {
  display: none;
}

.carrusel-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>