<template>
  <transition name="slide">
    <div
      v-if="isOpen"
      ref="modalRef"
      class="fixed top-0 right-0 w-80 h-full bg-white shadow-lg border-l border-gray-200 p-4 flex flex-col z-50"
    >
      <!-- Header -->
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-lg font-semibold text-neutral-900">Notifications</h2>
        <button @click="closeModal" class="text-gray-600 hover:text-gray-800">
          ✕
        </button>
      </div>

      <!-- Notification List -->
      <CircularLoader v-if="flagDeleting" />
      <ul class="space-y-4 flex-grow overflow-y-auto">
        <li
          v-for="notification in paginatedNotifications"
          :key="notification.id_notification"
          class="p-4 rounded-lg flex flex-col gap-2 border"
          :class="getNotificationClass(notification.is_read)"
        >
          <!-- Notification Content -->
          <div @click="cta(notification)" class="w-full cursor-pointer">
            <div class="text-gray-900 font-semibold text-left">
              {{ notification.title }}
            </div>
            <div class="text-gray-800 text-sm text-left">
              {{ notification.message }}
            </div>
            <div class="text-gray-600 text-xs text-left">
              {{ formatDate(notification.created_at) }}
            </div>
          </div>

          <!-- Action buttons -->
          <div class="flex gap-2 justify-end">
            <button
              @click="markAsRead(notification, true)"
              class="px-3 py-1 text-white bg-blue-600 hover:bg-blue-700 rounded"
            >
              <img src="/icons/login-register/eye-white.svg" class="w-5 h-5" />
            </button>
            <button
              @click="deleteNotification(notification.id_notification)"
              class="px-3 py-1 text-white bg-red-200 hover:bg-red-300 rounded"
            >
              <img src="/icons/projects/delete-red.svg" class="w-5 h-5" />
            </button>
          </div>
        </li>
      </ul>

      <!-- Pagination -->
      <div v-if="totalPages > 1" class="mt-4 flex justify-between items-center">
        <button
          @click="previousPage"
          :disabled="currentPage === 1"
          class="px-3.5 py-2 bg-white rounded-lg shadow border border-slate-300 flex justify-center items-center gap-2"
        >
          <div class="text-slate-500 text-sm font-medium">Previous</div>
        </button>
        <div class="text-slate-500 text-sm font-medium">
          Page {{ currentPage }} of {{ totalPages }}
        </div>
        <button
          @click="nextPage"
          :disabled="currentPage === totalPages"
          class="px-3.5 py-2 bg-white rounded-lg shadow border border-slate-300 flex justify-center items-center gap-2"
        >
          <div class="text-slate-500 text-sm font-medium">Next</div>
        </button>
      </div>
    </div>
  </transition>
  <ModalTaskDetails
    v-if="showModalTaskDetail"
    :id_task="id_task"
    :isOther="flagIsNotActiveTimer"
    :isOpen="showModalTaskDetail"
    @close="showModalTaskDetail = false"
  />
  <ModalViewExpense
    v-if="showModaExpenseView"
    :status="statusExpense"
    :id_expense="id_expense"
    :isOpen="showModaExpenseView"
    @close="showModaExpenseView = false"
  />
  <ModalViewBill
    v-if="showModaBillView"
    :id_bill="id_bill"
    :status="statusBill"
    :isOpen="showModaBillView"
    @close="showModaBillView = false"
  />
  <ModalViewTimesheet
    v-if="showModaTimesheetView"
    :id_timesheet="id_timesheet"
    :status="statusTimesheet"
    :isOpen="showModaTimesheetView"
    @close="showModaTimesheetView = false"
  />
</template>

<script>
import { computed, ref, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import ModalTaskDetails from "@/components/Projects/Tasks/TaskDetails.vue";
import ModalViewExpense from "@/components/Projects/Expenses/ExpenseDetails.vue";
import ModalViewBill from "@/components/Projects/Bills/BillDetails.vue";
import ModalViewTimesheet from "@/components/Projects/Timesheets/TimesheetDetails.vue";
import CircularLoader from "@/components/CircularLoader.vue";

export default {
  props: {
    isOpen: Boolean,
  },
  components: {
    ModalTaskDetails,
    ModalViewExpense,
    ModalViewBill,
    ModalViewTimesheet,
    CircularLoader,
  },
  emits: ["close"],
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const showModalTaskDetail = ref(false);
    const showModaExpenseView = ref(false);
    const modalRef = ref(null);
    const currentPage = ref(1);
    const itemsPerPage = 5;
    const isTimerActive = computed(() => store.getters.isTimerActive);
    const selectedTakeaway = computed(() => store.getters.selectedTakeaway);
    const flagIsNotActiveTimer = ref(false);
    const user_type = computed(() => store.state.user.type);
    const id_task = ref(null);
    const id_expense = ref(null);
    const statusExpense = ref(null);
    const showModaBillView = ref(false);
    const id_bill = ref(null);
    const statusBill = ref(null);
    const showModaTimesheetView = ref(false);
    const id_timesheet = ref(null);
    const statusTimesheet = ref(null);
    const flagDeleting = ref(false);
    const notifications = computed(() => store.getters.notificationsList);
    const totalPages = computed(() =>
      Math.ceil(notifications.value.length / itemsPerPage)
    );
    const paginatedNotifications = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage;
      return notifications.value.slice(start, start + itemsPerPage);
    });

    const previousPage = () => {
      if (currentPage.value > 1) currentPage.value--;
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value) currentPage.value++;
    };

    const cta = async (notification) => {
      if (
        notification.title === `Task asssigment!` ||
        notification.title === `Task completed!`
      ) {
        await markAsRead(notification, false);
        const notificationUrl = notification.cta_url.split(":");
        id_task.value = notificationUrl[1];
        if (isTimerActive.value && isTimerActive.value.toString() == "true") {
          if (
            id_task.value.toString() ==
            selectedTakeaway.value.id_task.toString()
          ) {
            toggleModalTaskDetail();
          } else {
            flagIsNotActiveTimer.value = true;
            toggleModalTaskDetail();
          }
        } else {
          if (user_type.value.toString() == "1") {
            flagIsNotActiveTimer.value = true;
          }
          toggleModalTaskDetail();
        }
      } else if (
        notification.title === `Expense submission!` ||
        notification.title === `Expense update!`
      ) {
        await markAsRead(notification, false);
        const notificationUrl = notification.cta_url.split(":");
        id_expense.value = notificationUrl[1];
        statusExpense.value = await store.dispatch(
          `fetchExpenseStatus`,
          id_expense.value
        );
        toggleModalExpenseView();
      } else if (
        notification.title === `Bill submission!` ||
        notification.title === `Bill update!`
      ) {
        await markAsRead(notification, false);
        const notificationUrl = notification.cta_url.split(":");
        id_bill.value = notificationUrl[1];
        statusBill.value = await store.dispatch(
          `fetchExpenseStatus`,
          id_bill.value
        );
        toggleModalBillView();
      } else if (
        notification.title === `Timesheet submission!` ||
        notification.title === `Timesheet update!`
      ) {
        await markAsRead(notification, false);
        const notificationUrl = notification.cta_url.split(":");
        id_timesheet.value = notificationUrl[1];
        statusTimesheet.value = await store.dispatch(
          `fetchTimesheetStatus`,
          id_timesheet.value
        );
        toggleModalTimesheetView();
      } else if (notification.cta_url) {
        await markAsRead(notification, false);

        router.push({ path: notification.cta_url });
      }
    };

    const markAsRead = async (not, fromButton) => {
      if (fromButton) {
        await store.dispatch("readNotification", {
          id_notification: not.id_notification,
          is_read: !not.is_read,
        });
      }
      if (not.is_read == false) {
        await store.dispatch("readNotification", {
          id_notification: not.id_notification,
          is_read: true,
        });
      }

      await store.dispatch("fetchNotfications", {
        page: 1,
        limit: 100,
        id_user: store.state.user.uid,
        search: "",
        types: ["action", "info"],
      });
    };

    const deleteNotification = async (id) => {
      flagDeleting.value = true;
      await store.dispatch("deleteNotification", id);
      await store.dispatch("fetchNotfications", {
        page: 1,
        limit: 100,
        id_user: store.state.user.uid,
        search: "",
        types: ["action", "info"],
      });
      flagDeleting.value = false;
    };

    const formatDate = (date) => {
      return new Date(date).toLocaleString();
    };

    const getNotificationClass = (isRead) => {
      return isRead
        ? "bg-gray-100 border-gray-300"
        : "bg-blue-200 border-blue-300";
    };

    const closeModal = () => {
      emit("close");
    };

    const handleClickOutside = (event) => {
      if (modalRef.value && !modalRef.value.contains(event.target)) {
        closeModal();
      }
    };

    function toggleModalTaskDetail() {
      showModalTaskDetail.value = !showModalTaskDetail.value;
    }

    function toggleModalExpenseView() {
      showModaExpenseView.value = !showModaExpenseView.value;
    }

    function toggleModalBillView() {
      showModaBillView.value = !showModaBillView.value;
    }

    function toggleModalTimesheetView() {
      showModaTimesheetView.value = !showModaTimesheetView.value;
    }

    onMounted(() => {
      document.addEventListener("click", handleClickOutside);
    });

    onUnmounted(() => {
      document.removeEventListener("click", handleClickOutside);
    });

    return {
      notifications,
      paginatedNotifications,
      currentPage,
      totalPages,
      previousPage,
      nextPage,
      markAsRead,
      deleteNotification,
      formatDate,
      getNotificationClass,
      closeModal,
      cta,
      modalRef,
      toggleModalTaskDetail,
      showModalTaskDetail,
      flagIsNotActiveTimer,
      id_task,
      showModaExpenseView,
      id_expense,
      statusExpense,
      showModaBillView,
      id_bill,
      statusBill,
      showModaTimesheetView,
      id_timesheet,
      statusTimesheet,
      flagDeleting,
    };
  },
};
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease-in-out;
}
.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
}
</style>
