<template>
  <div
    v-if="isOpen"
    class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
  >
    <LoaderSpinner v-if="flagLoading" />
    <div
      class="w-[572px] bg-white rounded-xl shadow flex flex-col max-h-[90vh] overflow-y-auto"
      v-else
    >
      <div
        class="p-3 bg-slate-100 rounded-tl-xl rounded-tr-xl border-b border-slate-200 flex justify-between items-center"
      >
        <div class="text-neutral-900 text-lg font-semibold">
          Assign Resources
        </div>
        <button
          @click="closeModal"
          class="p-1 bg-gray-400 rounded-[20px] justify-start items-start gap-2.5 flex"
          style="flex-grow: 0 !important"
        >
          <svg
            class="w-4 h-4 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>
      <div
        class="p-1 bg-white rounded-lg border border-slate-100 justify-start items-start flex"
      >
        <button
          @click="selectTab('Employees')"
          :class="
            selectedTab === 'Employees'
              ? 'px-3 py-1.5 bg-slate-100 rounded-lg justify-center items-center gap-2 flex'
              : 'px-3 py-1.5 rounded-lg justify-center items-center gap-2 flex'
          "
        >
          <div
            :class="
              selectedTab === 'Employees'
                ? 'text-blue_primary text-sm font-semibold'
                : 'text-gray-500 text-sm font-normal'
            "
          >
            Employees
          </div>
        </button>
        <button
          @click="selectTab('Contractors')"
          :class="
            selectedTab === 'Contractors'
              ? 'px-3 py-1.5 bg-slate-100 rounded-lg justify-center items-center gap-2 flex'
              : 'px-3 py-1.5 rounded-lg justify-center items-center gap-2 flex'
          "
        >
          <div
            :class="
              selectedTab === 'Contractors'
                ? 'text-blue_primary text-sm font-semibold'
                : 'text-gray-500 text-sm font-normal'
            "
          >
            Contractors
          </div>
        </button>
      </div>
      <div
        class="h-auto w-full px-4 flex-col justify-start items-start gap-5 flex"
      >
        <div
          class="h-auto w-full flex-col justify-start items-start gap-3 flex"
        >
          <div
            class="w-full h-auto p-4 rounded-lg border border-zinc-200 flex-col justify-start items-center gap-4 flex"
          >
            <input
              v-model="filter"
              type="text"
              placeholder="Search contributor..."
              class="w-full px-3 py-2 bg-slate-50 rounded-lg border border-slate-200"
            />
            <LoaderSpinner v-if="flagLoadingUsers" />
            <div
              v-else
              v-for="(user, index) in filteredUsers"
              :key="index"
              class="w-full justify-start items-center gap-4 inline-flex"
            >
              <div
                class="grow shrink basis-0 h-10 justify-start items-center gap-3 flex"
              >
                <div class="flex-col justify-start items-end inline-flex">
                  <img :src="user.photo" class="w-10 h-10 rounded-full" />
                </div>
                <div
                  class="grow shrink basis-0 flex-col justify-center items-start gap-0.5 inline-flex"
                >
                  <div
                    class="self-stretch text-neutral-900 text-sm font-medium font-['Public Sans'] leading-snug"
                  >
                    {{ user.name }} {{ user.last_name }}
                  </div>
                  <div
                    class="self-stretch text-slate-600 text-xs font-normal font-['Public Sans'] leading-[18px]"
                  >
                    {{ user.email }}
                  </div>
                </div>
              </div>
              <div
                :class="[
                  'w-5 h-5 relative border-2 rounded cursor-pointer',
                  checkedUsers.includes(user.id_user)
                    ? 'bg-blue_primary'
                    : 'border-blue-700',
                ]"
                @click="toggleCheckbox(user.id_user)"
              >
                <div
                  v-if="checkedUsers.includes(user.id_user)"
                  class="absolute inset-0 flex items-center justify-center text-white"
                >
                  ✓
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="px-4 pt-2 pb-6 justify-end items-end gap-3 inline-flex">
        <button
          class="px-3.5 py-[9px] bg-white rounded-lg shadow border border-slate-200 justify-center items-center gap-2 flex"
          @click="closeModal"
        >
          <div
            class="text-slate-600 text-sm font-semibold font-['Public Sans'] leading-snug"
          >
            Cancel
          </div>
        </button>

        <button
          @click="addResources()"
          class="px-3.5 py-[9px] bg-blue_primary rounded-lg shadow justify-center items-center gap-2 flex"
        >
          <div
            class="text-white text-sm font-semibold font-['Public Sans'] leading-snug"
          >
            Confirm
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import LoaderSpinner from "../CircularLoader.vue";

export default {
  props: {
    isOpen: Boolean,
    projectId: Number,
  },
  components: { LoaderSpinner },
  emits: ["close"],
  setup(props, { emit }) {
    const store = useStore();

    const filter = ref("");
    const checkedUsers = ref([]);
    const selectedTab = ref("Employees");
    const flagLoading = ref(false);
    const flagLoadingUsers = ref(false);
    const isMobileView = computed(() => store.getters.isMobileView);
    const users = ref([]);
    const usersInProject = ref([]);
    const user_id = computed(() => store.state.user.uid);

    const selectTab = async (tab) => {
      flagLoadingUsers.value = true;
      const resources = await store.dispatch("fetchResourceForAssign", {
        type: tab == "Employees" ? 2 : 3,
      });
      users.value = resources.data;
      selectedTab.value = tab;
      flagLoadingUsers.value = false;
    };

    const filteredUsers = computed(() => {
      return users.value
        .filter((user) => {
          const query = filter.value.toLowerCase();
          return (
            user.name.toLowerCase().includes(query) ||
            user.last_name.toLowerCase().includes(query)
          );
        })
        .slice(0, 10);
    });

    function closeModal() {
      emit("close");
    }

    function toggleCheckbox(userId) {
      const index = checkedUsers.value.indexOf(userId);
      if (index === -1) {
        checkedUsers.value.push(userId);
      } else {
        checkedUsers.value.splice(index, 1);
      }
    }

    onMounted(async () => {
      flagLoading.value = true;
      const resourcesInproject = await store.dispatch(
        "fetchResourcesInProject",
        { id_project: props.projectId }
      );

      for (const resource of resourcesInproject.data) {
        checkedUsers.value.push(resource.id_user);
      }

      const resources = await store.dispatch("fetchResourceForAssign", {
        type: selectedTab.value == "Employees" ? 2 : 3,
      });
      users.value = resources.data;
      flagLoading.value = false;
    });

    async function addResources() {
      flagLoading.value = true;
      const data = {
        resources: checkedUsers.value,
        id_project: props.projectId,
        changedBy: user_id.value
      };
      const resp = await store.dispatch("addProjectResources", data);
      if (resp.ok) {
        flagLoading.value = false;
        closeModal();
      }
    }

    return {
      filter,
      filteredUsers,
      closeModal,
      checkedUsers,
      toggleCheckbox,
      selectTab,
      selectedTab,
      flagLoading,
      flagLoadingUsers,
      addResources,
      usersInProject,
      isMobileView,
    };
  },
};
</script>

<style scoped>
/* Add any additional styling if needed */
</style>
